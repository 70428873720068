import React from "react";
import { Button, Modal } from "semantic-ui-react";

function RequestsModelHost({
  isLoading,
  logo,
  color,
  bgcolor,
  labol,
  onclick,
  user,
  pragraph,
  onsecOpen,
  you,
  setFirstOpen,
  onReload,
  succ,
}) {
  const [secOpen, setSecOpen] = React.useState(false);
  return (
    <div className="mx-auto rounded-2xl ">
      <Modal
        closeIcon
        open={secOpen}
        onClose={() => setSecOpen(false)}
        onOpen={() => setSecOpen(true)}
        className="w-[40rem] h-[19rem] rounded-2xl  "
        trigger={
          <div>
            <button
              className={` " flex px-2 ${color} " `}
              onClick={() => {
                setSecOpen(false);
              }}
            >
              {logo}
              <span className="text-black text-lg">{labol}</span>
            </button>
          </div>
        }
      >
        <div className="rounded-2xl flex justify-center mt-16 text-2xl">
          <Modal.Content>
            <p>
              هل أنت متأكد أنك تريد <span className={color}>{labol} </span>
              {you} {user} <br></br>
              <span className="flex justify-center">{pragraph}</span>
            </p>
          </Modal.Content>
        </div>
        <Modal.Actions className="border-0 bg-white p-0 m-0 flex justify-center mt-12 text-white">
          <button
            loading={isLoading}
            onClick={() => {
              setSecOpen(false);
              setFirstOpen(false);
            }}
            className=" w-32 h-12 rounded-lg text-maincolor border-2 border-maincolor mr-10 text-center "
          >
            إلغاء
          </button>
          <Button
            loading={isLoading}
            onClick={() => {
              onclick();
              setSecOpen(false);
              setFirstOpen(false);

              // onReload();
            }}
            className={` " w-32 h-12 ${bgcolor} rounded-lg  hover:${bgcolor}/80  text-black " `}
          >
            نعم
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default RequestsModelHost;
