import React, { useEffect, useState } from "react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Tab } from "semantic-ui-react";

const ManagementFinanceFees = () => {
  const validNum = (v) => /^[0-9]*\.?[0-9]*$/.test(v);

  const [fees, setFees] = useState("");
  const { run, isLoading, isError, error, data } = useAxios({ data: [] });
  useEffect(() => {
    run(authAxios.get(api.finances.payment)).then(() => {
      setFees(data?.fees);
    });
  }, [data?.fees, run]);

  const success = () =>
    toast.success(`تم إضافه نسبه رسوم ربنة`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handelsumbit = () => {
    const body = {
      fees: fees,
    };
    run(authAxios.post(api.finances.payment, body))
      .then(() => {
        success();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="md:w-full    ">
      <ToastContainer />
      <div className=" md:my-24  my-16 border-t-2   ">
        <h1 className="text-maincolor md:text-3xl font-medium text-right my-4 text-2xl   ">
          نسبة رسوم زبنة
        </h1>
        <p className=" text-deltext  font-bold  py-1 text-right  ">
          {!validNum(fees)
            ? "يجب أن تكتب نسبة من 0 الي 100"
            : fees > 100
            ? "يجب أن تكتب نسبة من 0 الي 100"
            : ""}
        </p>
        <div className=" items-end flex flex-col   mx-6 ">
          <input
            className="md:w-[320px]  md:h-[56px]  w-80  h-14 bg-[#16498614] text-right border-b-2 border-[#878787] my-2  hover:border-[#4B3869] focus:outline-none p-2"
            placeholder="نسبة مئوية "
            type="text"
            value={fees}
            onChange={(e) => setFees(e.target.value)}
          />
          <Button
            loading={isLoading}
            className={` "bg-[#4B3869] text-[#FCFCFC] md:w-[323px] md:h-[40px] w-80 h-9  rounded-lg m-0  mb-10  ${
              !validNum(fees)
                ? " bg-maincolor/50 text-black "
                : fees > 100
                ? " bg-maincolor/50 text-black "
                : "bg-maincolor text-white hover:bg-maincolor/90 "
            } "`}
            onClick={!validNum(fees) ? "" : fees > 100 ? "" : handelsumbit}
          >
            حفظ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManagementFinanceFees;
