import React from "react";

const PropertesDetailsPrice = ({ price, totalprice }) => {
  return (
    <div>
      <h1 className="text-right text-2xl  text-maincolor pt-4">
        سعر الليلة الواحدة
      </h1>
      <div className="flex flex-row-reverse flex-wrap px-16 pt-6  py-8 text-lg">
        <p className=" ml-16 ">
          {price}
          <span className="text-2xl text-maincolor pl-2">:ليلة عادية</span>
        </p>
        <p>
          {totalprice}
          <span className="text-2xl text-maincolor pl-2 ">
            :عطلة نهاية الأسبوع
          </span>
        </p>
      </div>
    </div>
  );
};

export default PropertesDetailsPrice;
