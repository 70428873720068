const api = {
  contactus: "/feedback/send-message/",
  auth: {
    login: "/admin/signIn",
  },
  admin: {
    host: "admin/requests/hosts",
    user: (userId) => `/user/idImage/${userId} `,
    listing: "/admin/requests/properties",
    properties: {
      default: "/properties/",
      all: "/admin/properties/all",
      reviews: "/reviews/",
    },
  },
  categories: {
    default: "/categories/",
    remove: (categoryId) => `/categories/${categoryId}`,
    getAll: "/categories/v2/all/",
    categoriesimg: "/categories/updateImage/",
  },
  amenities: {
    default: "/amenities/",
  },
  components: {
    default: "/components/",
  },
  rules: {
    default: "/rules/",
  },
  cities: {
    default: "/cities/",
    citiesimg: "/cities/updateImage/",
  },
  finances: {
    default: "/admin/wallet/finances",
    payment: "/payment/fees",
  },

  user: {
    userlist: "/admin/users/list/",
    userlostinfo: "/admin/users/user-info/",
    userinfoproperties: (userId) => `/admin/users/user-info/${userId}`,
    usertranction: (userId) =>
      `/admin/wallet/user-transactions?userId=${userId}`,
  },

  massages: {
    massagesHistory: "/chatting/roomsHistory",
    massageroom: (massageId) => `/chatting/room/${massageId}`,
  },

  wallet: {
    wallet: "/admin/wallet/users-wallets-summary",
    settlement: "/admin/wallet/create-settlement",
    refund: "/payment/wallet/reservation-refund",
  },

  feedback: {
    default: "/feedback",
  },
  settings: {
    settings: "/admin/admin-settings",
  },
};
export default api;
