import React, { useState, useEffect } from "react";
import background from "../../src/Assats/icon/logingimg.png";
import user_pic from "../../src/Assats/icon/UserAvatarW.png";
import gv_pic from "../../src/Assats/icon/GoodEveing.png";
import mornigicon from "../../src/Assats/icon/Icon awesome-sun.png";
import moringbackground from "../../src/Assats/icon/Group 10117@2x.png";
import SettingSavepassModel from "../Component/settingpage/setting-savepass-model";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import api from "../api";
import useAxios from "../lib/use-axios";
import { authAxios } from "../config/axios-config";
import { Tab } from "semantic-ui-react";

const Settings = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [forceReload, setForceReload] = React.useState(false);

  const { run, isLoading, isError, error, data } = useAxios({ data: {} });

  useEffect(() => {
    run(authAxios.get(api.settings.settings)).then(() => {
      setEmail(data?.data?.email);
      setName(data?.data?.name);
      setPasswordInput("");
    });
  }, [data?.data?.email, data?.data?.name, run, forceReload]);

  return (
    <div className="max-w-7xl mx-auto">
      <Tab.Pane
        className="bg-transparent shadow-none border-0"
        loading={isLoading}
      >
        <div>
          {new Date().getHours() <= 17 ? (
            <div className="flex justify-between  h-[201px] relative  rounded-xl border-0 shadow mx-4  max-w-7xl md:mx-auto  ">
              <img
                className="w-full h-full object-cover object-bottom absolute rounded-xl border-0  "
                src={moringbackground}
                alt="bg"
              />
              <div className="bg-maincolor/25 absolute top-0 left-0 w-full h-full text-white flex justify-between px-10 rounded-xl border-0">
                <div className="flex flex-col justify-center">
                  <h1 className="md:text-4xl text-xl flex ">
                    <img
                      className="w-[46px]  mx-2"
                      src={mornigicon}
                      alt="pic"
                    />
                    صباح الخير
                  </h1>
                </div>
                <div className="flex justify-center items-center ">
                  <img
                    className="md:w-20 md:h-20 w-12 h-12 mr-6 mx-4 "
                    src={user_pic}
                    alt="pic"
                  />
                  <div className="flex flex-col justify-center">
                    <h1 className="text-xl">{data?.data?.name}</h1>
                    <p>Super admin</p>
                    <p>{data?.data?.email}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-between  h-[201px] relative  rounded-xl border-0 shadow mx-4  max-w-7xl md:mx-auto  ">
              <img
                className="w-full h-full object-cover object-bottom absolute rounded-xl border-0  "
                src={background}
                alt="bg"
              />
              <div className="bg-maincolor/25 absolute top-0 left-0 w-full h-full text-white flex justify-between px-10 rounded-xl border-0">
                <div className="flex flex-col justify-center">
                  <h1 className="md:text-4xl text-xl flex ">
                    <img className="w-[46px]  mx-2" src={gv_pic} alt="pic" />
                    مساء الخير
                  </h1>
                </div>
                <div className="flex justify-center items-center ">
                  <img
                    className="md:w-20 md:h-20 w-12 h-12 mr-6 mx-4 "
                    src={user_pic}
                    alt="pic"
                  />
                  <div className="flex flex-col justify-center">
                    <h1 className="text-xl">{data?.data?.name}</h1>
                    <p>Super admin</p>
                    <p>{data?.data?.email}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="h-auto max-w-[1118px] bg-white rounded-xl border-0 shadow my-8 ">
            <div>
              <p className="text-maincolor text-2xl font-bold  text-right py-6 px-4">
                معلومات أساسية
              </p>
            </div>
            <div className="md:grid  md:grid-cols-2   lg:grid-cols-3    grid-row-3 gap-7 flex flex-wrap mx-10">
              <div className="flex flex-row-reverse justify-center w-80 h-14 mx-auto  border-b-2  border-bordecole my-2   hover:border-maincolor focus:outline-none  ">
                <input
                  type={passwordType}
                  onChange={handlePasswordChange}
                  value={passwordInput}
                  name="password"
                  className=" w-80  h-14 mx-auto bg-[#16498614]   border-bordecole   hover:border-maincolor focus:outline-none pr-4 text-right  "
                  placeholder="تغير كلة المرور "
                />
                <button
                  className="bg-[#16498614] pl-4 border-bordecole    hover:border-maincolor "
                  onClick={togglePassword}
                >
                  {passwordType === "password" ? (
                    <FaEye size={30} />
                  ) : (
                    <FaEyeSlash size={30} />
                  )}
                </button>
              </div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="الحساب الشخصي"
                className="bg-[#16498614] h-14 w-80 border-b-2 border-bordecole my-2  text-right hover:border-maincolor focus:outline-none p-2 mx-auto"
              />
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="الاسم"
                className="bg-[#16498614] h-14 w-80 border-b-2 border-bordecole my-2  text-right hover:border-maincolor focus:outline-none p-2 mx-auto"
              />
              <button>
                <SettingSavepassModel
                  user={name}
                  email={email}
                  newpass={passwordInput}
                  endpoint={api.settings.settings}
                  setForceReload={setForceReload}
                  name="حفظ"
                />
              </button>
            </div>
          </div>
        </div>
      </Tab.Pane>
    </div>
  );
};

export default Settings;
