import React from "react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import UserMessage from "./user-message";
import { Tab } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import routes from "../../routes";

const SidebareMessage = ({ resetMessages, reloadUsers, SetFristMag }) => {
  const [usersWithLastMessage, setUsersWithLastMessage] = React.useState([]);
  const { run, isLoading, isError, error, totalPages } = useAxios({
    data: [],
  });
  const history = useHistory();

  React.useEffect(() => {
    run(authAxios.get(api.massages.massagesHistory)).then(({ data }) => {
      const usersWithLastMessage = data?.resultChatRooms;
      usersWithLastMessage?.sort(
        (a, b) =>
          new Date(b?.lastMessage?.createdAt).getTime() -
          new Date(a?.lastMessage?.createdAt).getTime()
      );

      setUsersWithLastMessage(usersWithLastMessage);
      const firstSenderId = usersWithLastMessage[0].user._id;
      history.push(routes.massageroom(firstSenderId));
    });
  }, [run, reloadUsers, history]);

  return (
    <div className="flex justify-end overflow-hidden scroll-smooth overflow-y-scroll scrollbar-hide bg-white   ">
      <div className="w-72 h-[40rem]  py-16 ">
        <div className="text-right bg-white">
          <Tab.Pane loading={isLoading}>
            {Array.from(Array(usersWithLastMessage?.length).keys()).map((i) => (
              <UserMessage
                data={usersWithLastMessage.length && usersWithLastMessage[i]}
                resetMessages={resetMessages}
              />
            ))}
          </Tab.Pane>
        </div>
      </div>
    </div>
  );
};

export default SidebareMessage;
