import React from "react";
import InputFilter from "../Component/common/input-filter";
import WellatTable from "../Component/wellat/wellat-table";

const Wallet = () => {
  return (
    <div className="max-w-7xl mx-auto">
      <div className="w-full flex justify-start py-2 rounded shadow borde-0 flex-row-reverse  bg-white my-4 mr-16 ">
        <InputFilter
          transparent="true"
          name="name"
          label="...بحث بأسم المستخدم"
        />
      </div>
      <WellatTable />
    </div>
  );
};

export default Wallet;
