import React from "react";

const ManagementFinanceCard = ({ num, title, logo, bg, shadowbg }) => {
  return (
    <div className="mx-auto">
      <div
        className={` " ${bg}  ${shadowbg}   w-60 h-32 rounded-xl mx-auto flex justify-evenly  shadow   " `}
      >
        <div className="flex flex-col justify-center text-white font-bold text-xl">
          <p>{num}</p>
          <p>{title}</p>
        </div>
        <p
          className={` " text-backgroundGray ${shadowbg} flex flex-col justify-center shadow-inner  " `}
        >
          {logo}
        </p>
      </div>
    </div>
  );
};

export default ManagementFinanceCard;
