import React from "react";
import * as icons from "react-icons/fa";

const PropertesDetailsAmenitiesRules = ({ data }) => {
  const rul = data?.rules;
  const amenities = data?.amenities;
  return (
    <div>
      <div className="px-8 flex flex-col text-right py-8">
        <h1 className="text-2xl font-medium text-maincolor ">وسائل الراحة</h1>
        <div className="px-16 flex  flex-wrap justify-end">
          {amenities?.map((e) => {
            const Icon = icons[e?.icon];
            return (
              <p className="px-6 py-2 flex ">
                {e.name.ar}
                <p className="mb-1 ml-2 text-[#2e0543] ">
                  <Icon size={25} />
                </p>
              </p>
            );
          })}
        </div>
      </div>
      <div className="px-8 flex flex-col text-right py-2">
        <h1 className="text-2xl font-medium text-maincolor ">قواعد الوحدة</h1>
        <div className="px-16 flex flex-wrap  justify-end">
          {rul?.map((e) => {
            const Icon = icons[e?.icon];
            return (
              <p className="px-6 py-2 flex ">
                {e.name.ar}
                <p className="mb-1 ml-2 text-[#2e0543] ">
                  <Icon size={25} />
                </p>
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PropertesDetailsAmenitiesRules;
