import React from "react";

const PropertesDetailsPhoto = ({ pic }) => {
  return (
    <div className="md:grid md:grid-cols-7 md:grid-rows-2  flex flex-wrap gap-4 mx-4 py-8 items-center">
      {Array.from(Array(pic?.length).keys()).map((i) => (
        <div className="w-32 h-32 mx-auto">
          <img
            className="w-full h-full object-cover rounded-lg shadow-sm "
            src={pic && pic[i]?.url}
            alt="pic"
          />
        </div>
      ))}
    </div>
  );
};

export default PropertesDetailsPhoto;
