import React from "react";
import logo from "../../../src/Assats/icon/logo_2.png";
import { RiPagesLine, RiSettingsLine } from "react-icons/ri";
import { GoSettings, GoSmiley } from "react-icons/go";
import { FiSend, FiPower } from "react-icons/fi";
import { BsWallet2 } from "react-icons/bs";
import { MdOutlineFeedback } from "react-icons/md";
import { BiBuildingHouse } from "react-icons/bi";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import routes from "../../routes";
import { useHistory } from "react-router-dom";
import { useState } from "react/cjs/react.development";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <div className=" flex justify-end ">
      <div className=" lg:flex flex-col justify-between h-screen text-l font-medium bg-maincolor w-56  text-white py-6 rounded-l-2xl fixed  ml-16 hidden  ">
        <div className=" mr-2 sidebar ">
          <h1 className="pb-10 pt-3 w-[129px] ml-6">
            <img src={logo} alt="logo" />
          </h1>
          <ul>
            <li
              className={`    ${
                pathname.startsWith(routes.requests) ? "active" : ""
              } `}
            >
              <p></p>
              <p></p>
              <Link
                to={routes.requests}
                className="flex justify-end my-6 py-2 mr-3 text-lg font-bold "
              >
                الطلبات
                <RiPagesLine className="mb-2 ml-3 mr-3 " size={25} />
              </Link>
            </li>
            <li
              className={`  ${
                pathname.startsWith(routes.management) ? "active" : ""
              } `}
            >
              <p></p>
              <p></p>
              <Link
                to={routes.management}
                className="flex justify-end my-6 py-2 mr-3  text-lg font-bold "
              >
                قسم الإدارة
                <GoSettings className=" rotate-90 mb-2 ml-3 mr-3 " size={25} />
              </Link>
            </li>
            <li
              className={` ${
                pathname.startsWith(routes.userlist) ? "active" : ""
              } `}
            >
              <p></p>
              <p></p>
              <Link
                to={routes.userlist}
                className="flex justify-end my-6 py-2 mr-3  text-lg font-bold"
              >
                قائمة المستخدم
                <GoSmiley className="mb-2 ml-3 mr-3  " size={25} />
              </Link>
            </li>
            <li
              className={` ${
                pathname.startsWith(routes.properties) ? "active" : ""
              } `}
            >
              <p></p>
              <p></p>
              <Link
                to={routes.properties}
                className="flex justify-end my-6 py-2 mr-3  text-lg font-bold"
              >
                الوحدات
                <BiBuildingHouse className="mb-2 ml-3 mr-3  " size={25} />
              </Link>
            </li>
            <li
              className={` ${
                pathname.startsWith(routes.massages) ? "active" : ""
              } `}
            >
              <p></p>
              <p></p>
              <Link
                to={routes.massages}
                className="flex justify-end my-6 py-2 mr-3  text-lg font-bold"
              >
                الرسائل
                <FiSend className="mb-2 ml-3 mr-3   " size={25} />
              </Link>
            </li>
            <li
              className={` ${
                pathname.startsWith(routes.wallet) ? "active" : ""
              } `}
            >
              <p></p>
              <p></p>
              <Link
                to={routes.wallet}
                className="flex justify-end my-6 py-2 mr-3  text-lg font-bold"
              >
                المحفظة
                <BsWallet2 className="mb-2 mr-2 ml-3   " size={25} />
              </Link>
            </li>
            <li
              className={` ${
                pathname.startsWith(routes.feedback) ? "active" : ""
              } `}
            >
              <p></p>
              <p></p>
              <Link
                to={routes.feedback}
                className="flex justify-end my-6 py-2 mr-3  text-lg font-bold"
              >
                الآراء
                <MdOutlineFeedback className="mb-2 mr-2 ml-3   " size={25} />
              </Link>
            </li>
            <li
              className={` ${
                pathname.startsWith(routes.settings) ? "active" : ""
              } `}
            >
              <p></p>
              <p></p>
              <Link
                to={routes.settings}
                className="flex justify-end  my-4 py-2 mr-3 text-lg font-bold"
              >
                الإعدادات
                <RiSettingsLine
                  className=" rotate-90 mb-2 mr-2 ml-3 "
                  size={25}
                />
              </Link>
            </li>
          </ul>
        </div>
        <div className="ml-5">
          <button
            onClick={() => {
              history.push(routes.login);
            }}
            className="flex justify-end  ml-11 text-lg font-bold "
          >
            تسجيل خروج
            <FiPower className=" mx-2" size={25} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
