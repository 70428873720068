import React from "react";
import { IoMdSearch } from "react-icons/io";
import { Button, Input } from "semantic-ui-react";

import useFilter from "../../lib/use-filter";

const InputFilter = ({ name, label }) => {
  const [val, setVal] = React.useState("");
  const [filter, setFilter] = useFilter(name, "");

  return (
    <div className="flex justify-items-start flex-row-reverse  bg-backgroundGray gap-2 mx-6">
      <Input
        value={val}
        placeholder={label}
        onChange={(_, { value }) => setVal(value)}
      />
      <Button size="tiny" basic onClick={() => setFilter(val)}>
        <IoMdSearch size="16" />
      </Button>
    </div>
  );
};

export default InputFilter;
