import React from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, Tab, Table } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import routes from "../../routes";
import Avatar from "../common/Avatar";
import RangePagination from "../common/range-pagination";
import TransctionModel from "../user-list/user-deatils/Transction-model";

const WellatTable = () => {
  const [forceReload, setForceReload] = React.useState(false);

  const fixed = (e) => e?.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

  const { run, isLoading, data, isError, error, totalPages } = useAxios({
    withPagination: true,
  });

  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  React.useEffect(() => {
    run(authAxios.get(api.wallet.wallet));
  }, [run, forceReload]);
  return (
    <Tab.Pane loading={isLoading}>
      <Table celled className="border-0 border-none bg-backgroundGray  ">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="rounded-l-xl border-0 bg-headtable/10">
              <p className="text-end flex justify-center rounded-l-2xl text-headtable ">
                معاينة
              </p>
            </Table.HeaderCell>
            <Table.HeaderCell className="border-0 bg-headtable/10">
              <p className="text-end flex justify-center rounded-l-2xl text-headtable ">
                الأرباح المعلقة
              </p>
            </Table.HeaderCell>

            <Table.HeaderCell className=" border-0 bg-headtable/10">
              <p className="text-end flex justify-center text-headtable">
                إجمالي المعاملات{" "}
              </p>
            </Table.HeaderCell>
            <Table.HeaderCell className=" border-0 bg-headtable/10">
              <p className="text-end flex justify-center text-headtable">
                {" "}
                أرباح أسبوعين
              </p>
            </Table.HeaderCell>
            <Table.HeaderCell className=" border-0 bg-headtable/10">
              <p className="text-end flex justify-center text-headtable">
                {" "}
                أرباح الأسبوع الماضي
              </p>
            </Table.HeaderCell>
            <Table.HeaderCell className="rounded-r-2xl border-0 bg-headtable/10">
              <p className="text-end flex justify-center text-headtable">
                {" "}
                الاسم
              </p>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body className="border-0">
          {data?.data?.map((e) => (
            <Table.Row className="border-0  hover:bg-white rev  ">
              <Table.Cell className="border-0 rounded-l-xl text-center ">
                <div className="text-maincolor">
                  <TransctionModel
                    table={true}
                    pendingEarnings={e?.pendingEarnings}
                    id={e?._id}
                    onReload={onReload}
                  />
                </div>
              </Table.Cell>
              <Table.Cell className="border-0  text-center ">
                <div>{fixed(e?.pendingEarnings)}</div>
              </Table.Cell>
              <Table.Cell className=" border-0  text-center ">
                <div>{e?.totalTransactions}</div>
              </Table.Cell>
              <Table.Cell className=" border-0  text-center ">
                <div>{fixed(e?.lastTwoWeekEarning)}</div>
              </Table.Cell>
              <Table.Cell className=" border-0  text-center ">
                <div>{fixed(e?.lastWeekEarnings)}</div>
              </Table.Cell>
              <Table.Cell className="rounded-r-xl  border-0 text-center ">
                <div>
                  <Link
                    className="ml-8 flex justify-between "
                    to={routes.walletuserdetails(e._id)}
                  >
                    <Link
                      className="md:ml-10 "
                      to={routes.walletuserdetails(e._id)}
                    >
                      {e.name}
                    </Link>
                    <div className=" md:mr-11 w-10 h-10 object-fill  rounded-full ml-2">
                      {e.avatar ? (
                        <img
                          className="w-10 h-10 object-fill rounded-full"
                          src={e?.avatar}
                          alt=""
                        />
                      ) : (
                        <Avatar name={e?.name} />
                      )}
                    </div>
                    <p className="bg-headtable/10 w-32 py-3  px-2 text-headtable md:hidden block font-bold ">
                      الاسم
                    </p>
                  </Link>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
          {data?.data.length === 0 && (
            <Table.Row textAlign="center">
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center">
                <div className=" flex justify-between md:block  ">
                  <p className="  text-maincolor text-5xl  block font-bold py-4 ">
                    لا يوجد شيء في المحفظة
                  </p>
                </div>
              </Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell
              className="border-0 rounded-lg bg-backgroundGray"
              colSpan="5"
            >
              {data?.data.length === 0 ? (
                <div className="hidden ">
                  <RangePagination totalPages={totalPages} />
                </div>
              ) : (
                <div className="row ">
                  <RangePagination totalPages={totalPages} />
                </div>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Tab.Pane>
  );
};

export default WellatTable;
