import React from "react";
import ManagementCatagoriesEditModel from "./Management-catagories-edit-model";
import icon from "../../../src/Assats/icon/icon_1.png";
import ManagementAmentiessEditModel from "./Management-amentiess-edit-model";
import * as icons from "react-icons/fa";

const ManagementCard = ({
  nam,
  pic,
  logo,
  id,
  title,
  placeholderar,
  placeholderen,
  namevalar,
  namevalen,
  content,
  cond,
  endpont,
  endpointimg,
  reload,
}) => {
  const Icon = icons[logo];

  return (
    <div className="mx-auto ">
      <div className="grid grid-cols-2  mx-auto w-80 h-36 rounded-md shadow-md ">
        <div className=" row-span-2 mx-auto">
          {cond === "acr" ? (
            <div className="w-36 h-36 flex justify-center items-center rounded-l-lg text-[#2e0543] ">
              <Icon size={50} />
            </div>
          ) : (
            <img src={pic} className="w-36 h-36 mr-4 rounded-l-lg" alt="icon" />
          )}
        </div>
        <h2 className="pr-4 pt-8 text-lg font-medium flex justify-end ">
          {nam}
        </h2>
        <div className="flex justify-between border-t-2 ">
          {/* delet */}
          <button className="text-deltext "></button>
          {cond === "acr" ? (
            // Amentiess + components + ruls
            <button className="px-[23px]">
              <ManagementAmentiessEditModel
                text={"تعديل"}
                title={title}
                placeholderar={placeholderar}
                placeholderen={placeholderen}
                namevalar={namevalar}
                namevalen={namevalen}
                content={content}
                logo={<Icon />}
                upload={"ادرج رمز"}
                icon={icon}
                endpont={endpont}
                reload={reload}
              />
            </button>
          ) : (
            // Catagories + city
            <button className="px-[23px]">
              <ManagementCatagoriesEditModel
                text={"تعديل"}
                title={title}
                placeholderar={placeholderar}
                placeholderen={placeholderen}
                namevalar={namevalar}
                namevalen={namevalen}
                content={content}
                upload={"ادرج صوره"}
                pic={pic}
                icon={icon}
                reload={reload}
                endpont={endpont}
                endpointimg={endpointimg}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagementCard;
