import React, { useState, useEffect } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import login_pic from "../../../src/Assats/icon/UserAvatarP.png";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import routes from "../../routes";
import { Link } from "react-router-dom";
import { RiPagesLine, RiSettingsLine } from "react-icons/ri";
import { GoSettings, GoSmiley } from "react-icons/go";
import { BiBuildingHouse } from "react-icons/bi";
import { FiPower, FiSend } from "react-icons/fi";
import { BsWallet2 } from "react-icons/bs";
import logo from "../../../src/Assats/icon/logo_2.png";
import "./Sidebar.css";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import { MdOutlineFeedback } from "react-icons/md";

const Navbar = () => {
  const { pathname } = useLocation();

  const [nav, setNav] = useState(false);
  const handelNav = () => {
    setNav(!nav);
  };
  // const { run, isLoading, isError, error, data } = useAxios({ data: {} });

  // useEffect(() => {
  //   run(authAxios.get(api.settings.settings)).then(() => {});
  // }, [run]);
  // console.log(data);
  return (
    <div className="flex-grow">
      <div className="flex flex-col justify-between mb-8 py-8 lg:px-16 px-8  h-[80p] bg-white shadow-sm  lg:mr-52  mr:0  ">
        <div className="lg:flex hidden">
          <img className="w-10 h-10 mx-4 mb-6 " src={login_pic} alt="" />
          <div>
            <h3 className="text2-xl ">Zabnh Admin</h3>
            <p className="text-sm text-[#878787]">Super admin</p>
          </div>
          {/* <IoMdNotificationsOutline className="mt-1 ml-6" size={30} /> */}
        </div>
        <div
          onClick={handelNav}
          className=" lg:hidden cursor-pointer z-10 pt-2 h-10 "
        >
          {nav ? (
            <AiOutlineClose size={20} className="text-black" />
          ) : (
            <HiOutlineMenuAlt4 size={20} />
          )}
        </div>
        <div
          onClick={handelNav}
          className={
            nav
              ? " top-0 right-0  text-white  w-30 h-screen fixed bg-maincolor  transition-all z-10 sidebar  lg:hidden  flex  rounded-l-2xl  	"
              : " top-28 hidden bg-maincolor h-screen fixed transition-all sidebar  "
          }
        >
          <ul className=" flex justify-between flex-col">
            <div>
              <h1 className=" items-end  flex justify-end  mr-4  ">
                <img className="w-24 py-6" src={logo} alt="logo" />
              </h1>
              <li
                className={`    ${
                  pathname.startsWith(routes.requests) ? "active" : ""
                } `}
              >
                <p></p>
                <p></p>
                <Link
                  to={routes.requests}
                  className="flex justify-end my-6 mx-11 py-4  text-lg font-bold "
                >
                  <RiPagesLine size={25} />
                </Link>
              </li>
              <li
                className={`  ${
                  pathname.startsWith(routes.management) ? "active" : ""
                } `}
              >
                <p></p>
                <p></p>
                <Link
                  to={routes.management}
                  className="flex justify-end my-6 mx-11 py-4  text-lg font-bold "
                >
                  <GoSettings size={25} />
                </Link>
              </li>
              <li
                className={` ${
                  pathname.startsWith(routes.userlist) ? "active" : ""
                } `}
              >
                <p></p>
                <p></p>
                <Link
                  to={routes.userlist}
                  className="flex justify-end my-6 mx-11 py-4   text-lg font-bold"
                >
                  <GoSmiley size={25} />
                </Link>
              </li>
              <li
                className={` ${
                  pathname.startsWith(routes.properties) ? "active" : ""
                } `}
              >
                <p></p>
                <p></p>
                <Link
                  to={routes.properties}
                  className="flex justify-end my-6 mx-11 py-4   text-lg font-bold"
                >
                  <BiBuildingHouse size={25} />
                </Link>
              </li>
              <li
                className={` ${
                  pathname.startsWith(routes.massages) ? "active" : ""
                } `}
              >
                <p></p>
                <p></p>
                <Link
                  to={routes.massages}
                  className="flex justify-end my-6 mx-11 py-4  text-lg font-bold"
                >
                  <FiSend size={25} />
                </Link>
              </li>
              <li
                className={` ${
                  pathname.startsWith(routes.wallet) ? "active" : ""
                } `}
              >
                <p></p>
                <p></p>
                <Link
                  to={routes.wallet}
                  className="flex justify-end my-6 mx-11 py-4   text-lg font-bold"
                >
                  <BsWallet2 size={25} />
                </Link>
              </li>
              <li
                className={` ${
                  pathname.startsWith(routes.feedback) ? "active" : ""
                } `}
              >
                <p></p>
                <p></p>
                <Link
                  to={routes.feedback}
                  className="flex justify-end  my-6 mx-11 py-4 text-lg font-bold"
                >
                  <MdOutlineFeedback className="  " size={25} />
                </Link>
              </li>
              <li
                className={` ${
                  pathname.startsWith(routes.settings) ? "active" : ""
                } `}
              >
                <p></p>
                <p></p>
                <Link
                  to={routes.settings}
                  className="flex justify-end  my-6 mx-11 py-4 text-lg font-bold"
                >
                  <RiSettingsLine size={25} />
                </Link>
              </li>
            </div>

            <div className="flex flex-col justify-end">
              <li
                className={` ${
                  pathname.startsWith(routes.login)
                    ? "bg-white text-maincolor rounded-2xl  "
                    : ""
                } `}
              >
                <p></p>
                <p></p>
                <Link
                  to={routes.login}
                  className="flex justify-end py-4   mx-11 text-lg font-bold"
                >
                  <FiPower size={25} />
                </Link>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
