import React, { useState } from "react";
import * as icons from "react-icons/fa";

const PropertesDetailsTitle = ({ title, comp, min, max, des }) => {
  return (
    <div className="h-auto">
      <div className="flex flex-wrap  md:gap-8 gap-4 text-right flex-row-reverse px-8 py-8  ">
        <p className=" flex  ">
          <p className="pt-2 pr-1">{title}</p>
          <p className="text-maincolor text-2xl   "> : الاسم</p>
        </p>
        <h2 className="flex ">
          {comp &&
            comp?.map((c) => (
              <div className="flex pt-1  ">
                <p className="pt-1 pr-1 px-8">{c?.count}</p>
                <p className="text-maincolor text-xl">
                  :{c?.component?.name.ar}
                </p>
              </div>
            ))}

          <div></div>
        </h2>
        <h2 className="flex justify-end ">
          <p className="pt-1 pr-1">{min}</p>
          <p className="text-maincolor text-xl">:الحد الأدنى من الأيام</p>
        </h2>
        <h2 className="flex">
          <p className="pt-1 pr-1">{max}</p>
          <p className="text-maincolor text-xl">:الحد الأقصى للأيام</p>
        </h2>
      </div>
      <div>
        <p className="px-8 pb-8 text-right ">
          <span className="text-maincolor text-xl flex justify-end">
            :الوصف
          </span>
          {des}
        </p>
      </div>
    </div>
  );
};

export default PropertesDetailsTitle;
