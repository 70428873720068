import React from "react";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { IoIosEye } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { Tab, Table } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import routes from "../../routes";
import { constructAPIQuery } from "../../utils/pagination-utils";
import InputFilter from "../common/input-filter";
import RangePagination from "../common/range-pagination";
import PendingApprovalModal from "./pending-approval-model";
import RequestsModelHost from "./requests-model-host";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TableListingsRequests = () => {
  const [forceReload, setForceReload] = React.useState(false);

  const { search } = useLocation();
  const apiQuery = constructAPIQuery(search);
  const query = new URLSearchParams(search);
  const searchedName = query.get("name");

  const { run, isLoading, data, isError, error, totalPages } = useAxios({
    withPagination: true,
  });

  console.log(data);

  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  React.useEffect(() => {
    run(authAxios.get(`${api.admin.listing}${apiQuery}`));
  }, [run, search, apiQuery, forceReload]);

  const success = () =>
    toast.success(" تم اعتماد هذه الوحدة", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const err = () =>
    toast.error(" تم رفض هذا الوحدة", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const idAccept = (c) => {
    authAxios.put(api.admin.properties.default + c + "/accept").then(() => {
      // success();
      onReload();
    });
  };
  const idRejected = (c) => {
    authAxios.put(api.admin.properties.default + c + "/reject").then(() => {
      // err();
      onReload();
    });
  };

  return (
    <div>
      <ToastContainer />
      <Tab.Pane loading={isLoading}>
        <InputFilter name="name" label="... بحث باسم الوحدة " />
        <Table celled className="bg-backgroundGray border-0    border-none">
          <Table.Header
            className={`"${
              data?.length === 0 ? "hidden" : " md:contents"
            }   hidden  " `}
          >
            <Table.Row>
              <Table.HeaderCell className="rounded-l-2xl border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className="text-center text-headtable h-10  my-1 py-1">
                  معاينة
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className="text-center text-headtable h-10  my-1 py-1">
                  الحالة
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className="text-center text-headtable h-10  my-1 py-1">
                  التحقق من المضيف
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className="text-center text-headtable h-10  my-1 py-1">
                  {" "}
                  تاريخ الإدراج
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className="text-center  text-headtable h-10  my-1 py-1">
                  {" "}
                  المدينة{" "}
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0 ">
                <p className="text-center  text-headtable h-10  my-1 py-1 ">
                  {" "}
                  النوع
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className="rounded-r-2xl border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className="  text-headtable text-center h-10  my-1 py-1 ">
                  الاسم
                </p>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body className="border-0">
            {data?.map((e) => (
              <Table.Row className="border-0 hover:bg-white " key={e._id}>
                <Table.Cell className="rounded-l-xl border-0 text-center  ">
                  <div className="ml-6 flex justify-between md:block  ">
                    <button>
                      <Link
                        to={routes.userpropertiesdetails(
                          e?._id,
                          e?.host.idImageVerification
                        )}
                      >
                        <IoIosEye className="mx-auto" size={25} />
                      </Link>
                    </button>
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      معاينة
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="border-0 text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    <div>
                      <button>
                        {e?.host.idImageVerification === "verified" ? (
                          <RequestsModelHost
                            onclick={() => {
                              success();
                              idAccept(e._id);
                            }}
                            logo={<AiFillLike size={20} />}
                            isLoading={isLoading}
                            onReload={onReload}
                            color={"text-greenz"}
                            bgcolor={"bg-greenz"}
                            user={" الوحدة؟"}
                            you={"هذه"}
                            labol={"اعتماد"}
                          />
                        ) : (
                          <PendingApprovalModal
                            logo={<AiFillLike size={20} />}
                            color={"text-greenz"}
                            labol={"اعتماد"}
                          />
                        )}
                      </button>
                      <button>
                        <RequestsModelHost
                          onclick={() => {
                            err();
                            idRejected(e._id);
                          }}
                          logo={<AiFillDislike size={20} />}
                          isLoading={isLoading}
                          color={"text-redz"}
                          bgcolor={"bg-redz"}
                          user={" الوحدة؟"}
                          pragraph={" .لا يمكن الرجوع في هذا الإجراء"}
                          you={"هذه"}
                          labol={"رفض"}
                        />
                      </button>
                    </div>
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      الحاله
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="border-0 text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {e.host.idImageVerification === "verified" ? (
                      <div className="text-greenz">نعم</div>
                    ) : (
                      <div className="text-[#F6C054]">منتظر التصديق</div>
                    )}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      التحقق من المضيف
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="border-0 text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {new Date(e.createdAt).toLocaleDateString("en-GB")}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      تاريخ الأدراج
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="border-0 text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {e?.mainCity?.name?.ar}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      المدينه
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="border-0 text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {e?.category?.name?.ar}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      النوع
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="rounded-r-xl border-0 text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {e?.name}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      الاسم
                    </p>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}

            {data?.length === 0 && !searchedName && (
              <Table.Row textAlign="center">
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>

                <Table.Cell className="border-0 text-center">
                  <div className=" flex justify-between md:block  ">
                    <p className="  text-maincolor text-5xl  block font-bold py-4 ">
                      لا توجد وحدات بعد
                    </p>
                  </div>
                </Table.Cell>

                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
              </Table.Row>
            )}
            {data?.length === 0 && searchedName && (
              <Table.Row textAlign="center">
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center">
                  <div className=" flex justify-between md:block  ">
                    <p className="  text-maincolor text-5xl  block font-bold py-4 ">
                      لا توجد وحدات بهذا الإسم
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell
                className="border-0 rounded-lg bg-backgroundGray"
                colSpan="7"
              >
                {data?.length === 0 ? (
                  <div className="hidden ">
                    <RangePagination totalPages={totalPages} />
                  </div>
                ) : (
                  <div className="row ">
                    <RangePagination totalPages={totalPages} />
                  </div>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Tab.Pane>
    </div>
  );
};
export default TableListingsRequests;
