import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import routes from "../../../routes";
import RatingExampleHeart from "../../common/rate";

const ListingCard = ({ nam, pic, city, average, id }) => {
  const truncateString = (str, num) => {
    if (str?.length > num) {
      return "..." + str.slice(0, num);
    } else {
      return str;
    }
  };
  const { propertyId } = useParams();
  const { userId } = useParams();
  const { pathname } = useLocation();
  console.log(id);
  console.log(userId);
  console.log(propertyId);
  return (
    <div className="mx-auto">
      <div className="grid grid-cols-2  mx-auto w-80 h-36 rounded-md shadow-md ">
        <div className=" row-span-2 mx-auto">
          <img alt="" src={pic} className="w-36 h-36 mr-4 rounded-l-lg" />
        </div>
        <h2 className="pr-4 py-2  flex flex-col text-right justify-end">
          {truncateString(nam, 15)}{" "}
          <span className="flex justify-end py-2">{city}</span>
        </h2>
        <div className="flex justify-between border-t-2 ">
          <button className="text-deltext">
            <RatingExampleHeart max={average} />
          </button>
          <Link
            to={
              pathname.startsWith(routes.requests)
                ? routes.userpropertiesdetails(id)
                : pathname.startsWith(routes.userlist)
                ? routes.userdetailsproperties(id)
                : routes.walletuserdetailsProperties(id)
            }
          >
            <button className="px-3 py-4">معاينة</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
