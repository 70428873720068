import React, { useState } from "react";
import contact from "../../../../src/pages/landing-page/assets/Contact Us/Mask Group 4@2x.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, Segment } from "semantic-ui-react";
import { useLanguage } from "../context/Language-Context";
import { content } from "../localization/content";
import axios from "axios";
import api from "../../../api";
import useAxios from "../../../lib/use-axios";
import FormikInput from "./formik/formik-input";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PhoneForm from "./formik/phoneForm";

const ContactUs = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [message, setmessage] = useState();
  const [phone, setphone] = useState();

  const [relod, setRelod] = useState(true);

  const { run, isLoading, isError, error, data } = useAxios();

  const [forceReload, setForceReload] = React.useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  React.useEffect(() => {}, [forceReload]);

  const registerSchema = Yup.object({
    name: Yup.string()
      .min(
        2,
        `${lang === "en" ? "minimum character 3 " : "الحد الأدنى من الحرف 3"}`
      )
      .max(
        24,
        `${lang === "en" ? "maximum character 25 " : "الحد الأقصى من الحرف 25"}`
      )
      .required(`${lang === "en" ? "required " : " مطلوب"}`),
    email: Yup.string()
      .email(
        `${lang === "en" ? "email not valid " : " البريد الإلكتروني غير صالح"}`
      )
      .required(`${lang === "en" ? "required " : " مطلوب"}`)
      .trim(),
  });

  const submit = (values) => {
    const body = {
      name: values.name,
      email: values.email,
      phoneNumber: phone,
      message: message,
    };
    run(axios.post(api.contactus, body)).then(() => {
      setphone("");
      setmessage("");
      setemail("");
      setname("");
      onReload();
    });
  };

  return (
    <div id="Contact_us" className="w-full  ">
      <Segment className="border-none shadow-none bg-transparent  p-0 m-0">
        <div className="max-w-7xl mx-auto pb-24">
          <h1 className="text-center font-bold text-3xl py-8 pt-20">
            {langContent.ContactUs}
          </h1>
          <div className="grid md:grid-cols-2  grid-cols-1 mx-4 ">
            <div className="flex flex-col justify-center w- mx-auto  pt-9">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phoneNumber: "",
                }}
                onSubmit={submit}
                validationSchema={registerSchema}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="mx-auto">
                      <FormikInput
                        label={langContent.FullName}
                        placeholder={langContent.FullName}
                        name="name"
                      />
                      <FormikInput
                        label={langContent.Email}
                        placeholder={langContent.Email}
                        name="email"
                      />
                      {/* <PhoneForm name="phoneNumber" /> */}
                      <div dir="ltr" className="">
                        <PhoneInput
                          dir=""
                          country={"sa"}
                          onChange={(phone) => setphone(phone)}
                        />
                      </div>
                    </div>
                    <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                      {lang === "en"
                        ? error?.errors[0]?.message
                        : "يجب إرسال رقم الهاتف"}
                    </span>

                    <textarea
                      className="mx-auto md:w-[24rem] w-[20rem]  my-2  rounded-xl p-4 outline-none border-[1px] border-maincolor"
                      placeholder={langContent.write}
                      onChange={(e) => setmessage(e.target.value)}
                    ></textarea>
                    <span className="h-4 my-2 text-green-600 text-left text-sm flex  ">
                      {lang === "en" ? data?.message?.en : data?.message?.ar}
                    </span>
                    <div className="flex flex-col justify-between  ">
                      <Button
                        loading={isLoading}
                        className="bg-maincolor text-white  md:w-[24rem] w-[20rem]  h-10 rounded-lg "
                        type="submit"
                      >
                        {langContent.submit}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div>
              <img
                className="md:w-[30rem] md:h-[25rem] w-[19rem] h-[17rem]    mx-auto md:my-0 my-12"
                src={contact}
                alt=""
              />
            </div>
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default ContactUs;
