import React from "react";
import PropertiesTable from "../../Component/properties/Properties-table";
import InputFilter from "../../Component/common/input-filter";

const Properties = () => {
  return (
    <div className="max-w-7xl mx-auto w-full ">
      <div className="w-full flex justify-end py-2 rounded shadow borde-0  bg-white my-4  ">
        <InputFilter
          transparent="true"
          name="name"
          label="...بحث بأسم الوحدة "
        />
      </div>
      <PropertiesTable />
    </div>
  );
};

export default Properties;
