import React from "react";
import { Tab } from "semantic-ui-react";
import TableHostRequests from "./requests-host-table";
import TableListingsRequests from "./requests-listings-table";
import "../../../src/Assats/style/requests-tab-style.css";

const panes = [
  {
    menuItem: "المضيفون",
    render: () => <TableHostRequests />,
  },
  {
    menuItem: "الوحدات",
    render: () => <TableListingsRequests />,
  },
];
const ReaquestTabs = () => {
  return <Tab menu={{ secondary: true }} panes={panes} className="filled" />;
};

export default ReaquestTabs;
