import React from "react";
import { useParams } from "react-router-dom";
import { Pagination, Tab } from "semantic-ui-react";
import api from "../../../api";
import { authAxios } from "../../../config/axios-config";
import useAxios from "../../../lib/use-axios";
import { constructAPIQuery } from "../../../utils/pagination-utils";
import RangePagination from "../../common/range-pagination";
import ListingCard from "./user-details-listing-card";

const ListiingNestTabOne = () => {
  const { userId } = useParams();
  const apiQuery = constructAPIQuery();

  const { run, isLoading, data, isError, error, totalPages } = useAxios({
    withPagination: true,
  });

  React.useEffect(() => {
    run(
      authAxios.get(
        `${api.user.userinfoproperties(
          userId
        )}/properties${apiQuery}&status=published`
      )
    );
  }, [run, userId, apiQuery]);

  return (
    <div className="">
      {data?.length === 0 && (
        <div className="text-6xl font-bold text-maincolor flex justify-center py-24">
          لا يوجد وحدات نُشرت بعد
        </div>
      )}
      <Tab.Pane loading={isLoading}>
        <div className="  flex flex-row-reverse flex-wrap  lg:mr-24   gap-16 pt-10 ">
          {data?.map((c) => (
            <div key={c.property._id}>
              <ListingCard
                id={c?.property._id}
                average={Math.round(c.rate.average)}
                nam={c?.property?.name}
                city={c?.property?.mainCity.name?.ar}
                pic={c?.property?.pictures[0]?.url}
              />
            </div>
          ))}
        </div>
        <div className="row  py-12   ">
          {data?.length === 0 ? (
            <div className="hidden ">
              <RangePagination totalPages={totalPages} />
            </div>
          ) : (
            <div className="row ">
              <RangePagination totalPages={totalPages} />
            </div>
          )}
        </div>
      </Tab.Pane>
    </div>
  );
};

export default ListiingNestTabOne;
