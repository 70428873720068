import React from "react";
import Navbar from "./Component/Navbar";
import HeroSection from "./Component/Hero-Section";
import OurServices from "./Component/Our-Services";
import AboutUs from "./Component/About-Us";
import ContactUs from "./Component/Contact-Us";
import Footor from "./Component/Footor";
import { LanguageProvider } from "./context/Language-Context";

function landingpage() {
  return (
    <>
      <LanguageProvider>
        <div className="bg-[#FFFFFF]">
          <Navbar />
          <div className="max-w-7xl mx-auto"></div>
          <HeroSection />
          <OurServices />
          <AboutUs />
          <ContactUs />
        </div>
        <div>
          <Footor />
        </div>
      </LanguageProvider>
    </>
  );
}

export default landingpage;
