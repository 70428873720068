import React from "react";
import { Table } from "semantic-ui-react";
import ImgBiggerModel from "./Img-biger-model";

const UserListTransctionSettlementsTable = ({ settlements }) => {
  return (
    <div className="w-full">
      <Table className="border-0 border-none bg-backgroundGray pb-16">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="w-full text-maincolor text-2xl bg-backgroundGray flex border-0 border-none flex-row-reverse py-2  my-4 mr-6">
              المعاملات
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className="">
          <Table.Row className="flex flex-row  justify-between px-16">
            <Table.Cell className=" w-full border-0  text-center mx-auto text-headtable text-xl">
              المستند
            </Table.Cell>
            <Table.Cell className=" w-full  border-0 text-center mx-auto text-headtable text-xl">
              تاريخ التحويل
            </Table.Cell>
            <Table.Cell className=" w-full   border-0 text-center mx-auto text-headtable text-xl">
              القيمة
            </Table.Cell>
          </Table.Row>
          {settlements?.map((e) => (
            <Table.Row className="flex  justify-between  px-16 ">
              <Table.Cell className=" w-full border-b-2 text-center border-0">
                <button className=" w-36 h-20 ">
                  <ImgBiggerModel pic={e?.attachmentUrl} />
                </button>
              </Table.Cell>
              <Table.Cell className=" w-full   border-b-2  text-center mx-auto  border-0">
                {new Date(e?.createdAt).toLocaleDateString("en-GB")}
              </Table.Cell>
              <Table.Cell className=" w-full border-0 mx-auto  border-b-2  text-center">
                {e?.amount}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default UserListTransctionSettlementsTable;
