import Axios from "axios";

Axios.defaults.baseURL =
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_SERVER_URL;

const AuthAxios = Axios.create();

AuthAxios.interceptors.request.use(async (config) => {
  config.headers = {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
  };
  return config;
});

export const axios = Axios;
export const authAxios = AuthAxios;
