import React, { useState } from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ENTER_KEY = 13;

function SettingSavepassModel({
  name,
  user,
  email,
  newpass,
  endpoint,
  setForceReload,
}) {
  const [open, setOpen] = React.useState(false);

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const [err, setErr400] = useState(false);

  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handelKeyDowen = (e) => {
    if (e.which === ENTER_KEY) handelsumbit();
  };

  const { run, isLoading, isError, error, data } = useAxios({ data: {} });

  const handelsumbit = () => {
    const body = {
      name: user,
      email: email,
      newPassword: newpass,
      oldPassword: passwordInput,
    };

    const success = () =>
      toast.success(`  تم تغير كلمة المرور بنجاح   `, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    run(authAxios.post(endpoint, body))
      .then(() => {
        setOpen(false);
        setPasswordInput("");
        success();
        setForceReload((p) => !p);
      })
      .catch((err) => {
        // err?.errors[0].message === "The old password is not correct"
        //   ? true
        //   : false;
        console.log(
          err?.errors[0]?.message === "The old password is not correct"
            ? setErr400(true)
            : setErr400(false)
        );
      });

    console.log(body);
  };

  return (
    <div className="mx-auto">
      <ToastContainer />
      <Modal
        className="w-96 h-auto rounded-xl"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        closeIcon
        open={open}
        trigger={
          <Button className="bg-[#4B3869] text-[#FCFCFC] w-80 h-14 rounded-lg mx-auto mb-16">
            {name}
          </Button>
        }
      >
        {/* edit model here */}
        <div className="w-96 mx-auto flex flex-col justify-between ">
          <h1 className="mx-auto my-11 text-2xl text-center font-bold">
            إدخال كلمة المرور القديمه
          </h1>
          <h1 className="mx-auto text-deltext  text-xl text-center ">
            {err ? " كلمة المرور غير صحيحة" : ""}
          </h1>

          <div className="flex flex-row-reverse justify-center w-80 h-14 mx-auto   my-3 mt-5  border-b-2 border-maincolor focus:outline-none  ">
            <input
              onKeyUp={handelKeyDowen}
              type={passwordType}
              onChange={handlePasswordChange}
              value={passwordInput}
              name="password"
              className=" w-80  h-14 mx-auto bg-[#16498614]  border-b-2 border-maincolor focus:outline-none pr-4 text-right  "
              placeholder=" كلة المرور القديمة"
            />
            <button className="bg-[#16498614] pl-4 " onClick={togglePassword}>
              {passwordType === "password" ? (
                <FaEye size={30} />
              ) : (
                <FaEyeSlash size={30} />
              )}
            </button>
          </div>

          <Button
            type="submit"
            // onClick={handelsumbit}
            onClick={passwordInput.length < 6 ? "" : handelsumbit}
            className={` ${
              passwordInput.length < 6
                ? " bg-maincolor/50 text-black mt-8 w-80 h-14 rounded-lg mx-auto mb-8"
                : " bg-maincolor mt-8 w-80 h-14  text-[#FCFCFC] rounded-lg mx-auto mb-8"
            } `}
            loading={isLoading}
            // className="bg-maincolor mt-8 w-80 h-14  text-[#FCFCFC] rounded-lg mx-auto mb-8"
          >
            إرسال
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default SettingSavepassModel;
