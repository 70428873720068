import React from "react";
import { Tab } from "semantic-ui-react";
import "../../../../src/Assats/style/management-finance-tab-style.css";
import ListingCard from "./user-details-listing-card";
import { Pagination } from "semantic-ui-react";
import ListiingNestTabOne from "./listiing-nest-tab-one";
import ListiingNestTabTwo from "./listiing-nest-tab-two";
import ListiingNestTabThree from "./listiing-nest-tab-three";

const panes = [
  {
    menuItem: "منشور",
    render: () => (
      <Tab.Pane className=" mx-24 sm:mx-0 w-full">
        <div className=" h-auto  md:rounded-xl md:shadow ">
          <ListiingNestTabOne />
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: "قيد الانتظار ",
    render: () => (
      <Tab.Pane className=" mx-24 sm:mx-0 w-full">
        <div className=" h-auto  md:rounded-xl md:shadow ">
          <ListiingNestTabTwo />
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: "  مؤرشف",
    render: () => (
      <Tab.Pane className=" mx-24 sm:mx-0 w-full">
        <div className=" h-auto  md:rounded-xl md:shadow ">
          <ListiingNestTabThree />
        </div>
      </Tab.Pane>
    ),
  },
];

const DetailsListingNestTab = () => (
  <div>
    <div className="sm:hidden md bg-backgroundGray   ">
      <Tab menu={{ fluid: false, vertical: true }} panes={panes} />
    </div>
    <div className="sm:block hidden bg-backgroundGray ">
      <Tab
        menu={{ borderless: true, attached: false, tabular: false }}
        panes={panes}
      />
    </div>
  </div>
);

export default DetailsListingNestTab;
