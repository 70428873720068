import React from "react";
import { Button, Modal } from "semantic-ui-react";
import * as icons from "react-icons/fa";

const IconModel = ({ geticon }) => {
  const [open, setOpen] = React.useState(false);
  const [ic, setIcon] = React.useState(null);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button></Button>}
      closeIcon
    >
      <div className="  max-w-[900px]  ">
        <Modal.Content image scrolling>
          <div className="max-w-[900px] h-[800px]  text-6xl  text-[#2e0543]   ">
            {Object.keys(icons).map((icon) => {
              const Icon = icons[icon];
              return (
                <label>
                  <button
                    className="py-2 px-2 "
                    onClick={(e) => {
                      setIcon(e?.target?.value);
                      geticon(icon);
                      setOpen(false);
                    }}
                  >
                    <Icon />
                  </button>
                </label>
              );
            })}
          </div>
        </Modal.Content>
      </div>
    </Modal>
  );
};

export default IconModel;
