import { React } from "react";
import { Tab } from "semantic-ui-react";
import "../../../src/Assats/style/management-tab-style.css";

import ManagementFinanceTab from "./Management-finance-tabs";
import ManagementCategories from "./Management-categories";
import MangementAmenities from "./Mangement-amenities";
import MangementComponents from "./Mangement-Components";
import MangementHouseRules from "./Mangement-house-rules";
import MangementCity from "./Mangement-city";
import ManagementFinanceFees from "./Management-finance-fees";

const panes = [
  {
    menuItem: "المالية",
    render: () => (
      <div className="mx-auto">
        <ManagementFinanceTab />
      </div>
    ),
  },
  {
    menuItem: "الفئات",
    render: () => (
      <div className="mx-auto">
        <ManagementCategories />
      </div>
    ),
  },
  {
    menuItem: "وسائل الراحة",
    render: () => (
      <div>
        <MangementAmenities />
      </div>
    ),
  },
  {
    menuItem: "المكونات",
    render: () => (
      <div>
        <MangementComponents />
      </div>
    ),
  },
  {
    menuItem: "قواعد الوحدة",
    render: () => (
      <div>
        <MangementHouseRules />
      </div>
    ),
  },
  {
    menuItem: "المدن",
    render: () => (
      <div>
        <MangementCity />
      </div>
    ),
  },
];

const ManagemenTabs = () => {
  return (
    <div>
      <div className="md:hidden md bg-backgroundGray mx-auto  ">
        <Tab menu={{ fluid: true, vertical: true }} panes={panes} />
      </div>
      <div className="md:block hidden bg-backgroundGray mx-auto ">
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </div>
    </div>
  );
};

export default ManagemenTabs;
