import React from "react";
import { useParams } from "react-router-dom";
import TransctionModel from "./Transction-model";

const UserListTransctionEarnings = ({ earning, onReload }) => {
  const { userId } = useParams();
  const fixed = (e) => e?.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

  return (
    <div className="py-4   ">
      <h1 className=" text-maincolor text-2xl font-bold bg-backgroundGray text-right py-2 my-4 mr-6">
        الأرباح
      </h1>
      <div className="md:flex md:flex-row justify-between  border-b-2  flex-col-reverse  mx-16">
        <div className="pt-4 flex flex-col gap-2">
          <button className="text-center py-2 pb-8">
            <TransctionModel
              pendingEarnings={earning?.pendingEarnings}
              id={userId}
              onReload={onReload}
            />
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <h1>أرباح معلقة</h1>
          <p className="text-center py-2 pb-8">
            {fixed(earning?.pendingEarnings)}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h1>أرباح أسبوعين</h1>
          <p className="text-center py-2 pb-8">
            {fixed(earning?.lastTwoWeekEarnings)}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h1>أرباح الأسبوع الماضي</h1>
          <p className="text-center py-2 pb-8">
            {fixed(earning?.lastWeekEarnings)}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h1>إجمالي الحجوزات</h1>
          <p className="text-center py-2 pb-8">{earning?.totalTransactions}</p>
        </div>
      </div>
    </div>
  );
};

export default UserListTransctionEarnings;
