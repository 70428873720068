import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { AiOutlineStop } from "react-icons/ai";
import routes from "../../routes";
import { useHistory, useNavigate } from "react-router-dom";
import UserDetailsTab from "../../Component/user-list/user-deatils/user-list-details-tabs";
import { useLocation } from "react-router-dom";

const Userdetails = ({ backPPath }) => {
  const { pathname } = useLocation();

  const history = useHistory();
  return (
    <div className="bg-backgroundGray max-w-7xl mx-auto  ">
      <div className="w-full h-16 flex flex-row-reverse justify-between borde-0 my-4 rounded-xl shadow  bg-white px-10">
        <button
          className="text-headtable font-bold  flex mt-5"
          onClick={() => history.push(backPPath)}
        >
          <p>العوده إلي الخلف</p>
          <BiRightArrowAlt size={25} />
        </button>
        <button className="text-deltext  flex ">
          {/* <p className="font-bold px-2 mt-6">تعليق المستخدم</p> */}
          {/* <AiOutlineStop className="mt-5 font-bold " size={25} /> */}
        </button>
      </div>

      <div className="py-4">
        <UserDetailsTab />
      </div>
    </div>
  );
};

export default Userdetails;
