import React from "react";
import { Route, useHistory } from "react-router-dom";
import { Switch } from "react-router-dom";
import LeftSide from "../Component/message/left-side-message";
import SidebareMessage from "../Component/message/sidebare-message";
import routes from "../routes";
import noMsg from "../../src/Assats/icon/no_messages_blank_state.webp";

const Massages = () => {
  const [reloadUsers, setReloadUsers] = React.useState(false);
  const [messagesTo, setMessagesTo] = React.useState(9);
  const getOlderMessages = () => {
    setMessagesTo((prev) => prev + 9);
  };
  const resetMessages = () => setMessagesTo(9);

  return (
    <div>
      <div className=" h-[40rem] w-full flex flex-row-reverse  rounded-lg shadow  bg-white  max-w-7xl mx-auto  ">
        <SidebareMessage
          resetMessages={resetMessages}
          reloadUsers={reloadUsers}
        />

        <div className="w-full ">
          <Switch>
            <Route
              exact
              path={routes.massageroom()}
              component={() => (
                <LeftSide
                  from={0}
                  to={messagesTo}
                  getOlderMessages={getOlderMessages}
                  resetMessages={resetMessages}
                  onNewMessage={() => setReloadUsers((p) => !p)}
                />
              )}
            />
            <div className="  text-maincolor text-5xl  block font-bold py-4 "></div>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Massages;
