import React from "react";
import { useHistory } from "react-router-dom";
import { Pagination } from "semantic-ui-react";

import useFilter from "../../lib/use-filter";
import useQuery from "../../lib/use-query";

const RangePagination = ({ totalPages }) => {
  const [page] = useFilter("page", 1);

  const history = useHistory();
  const query = useQuery();

  const onPageChange = (selectedPage) => {
    query.set("page", selectedPage);
    history.replace(`?${query.toString()}`);
  };

  return (
    <Pagination
      activePage={page}
      firstItem={null}
      lastItem={null}
      secondary
      totalPages={totalPages}
      onPageChange={(_, { activePage }) => onPageChange(activePage)}
    />
  );
};

export default RangePagination;
