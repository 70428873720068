import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import IconModel from "../common/IconModel";
import checkmark from "../../../src/Assats/icon/checkmark.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}
function ManagementModalAmentities({
  text,
  title,
  content,
  placeholderar,
  placeholderen,
  upload,
  icon,
  endpont,
  reload,
}) {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;

  const { run, isLoading, isError, error, data } = useAxios({ data: [] });

  const [err, setEror] = useState(false);

  const [gtico, setGeticon] = useState(null);

  const [titar, setTitelAr] = useState("");
  const [titen, setTitelEn] = useState("");

  const isItAllArabic = (s) =>
    !/[^\u0600-\u06FF\u0020-\u0040\u005B-\u0060\u007B-\u007E]/.test(s);

  const success = () =>
    toast.success(`  تم ${content}   `, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handelsumbit = () => {
    const body = {
      name_ar: titar,
      name_en: titen,
      icon: gtico,
    };
    run(authAxios.post(endpont, body))
      .then(() => {
        success();
        dispatch({ type: "CLOSE_MODAL" });
        reload();
        setGeticon(null);
        setEror(false);
      })
      .catch((err) => {
        const message = err.errors[0].message;
        if (
          message === "Amenity already exist" ||
          "Components already exist" ||
          "House rules already exist"
        ) {
          setEror(true);
        }
      });
  };
  return (
    <div className="mx-auto ">
      <ToastContainer />
      <Modal
        dimmer={dimmer}
        closeIcon
        open={open}
        onClose={() =>
          dispatch(
            { type: "CLOSE_MODAL" },
            setGeticon(null),
            setEror(false),
            reload()
          )
        }
        className="w-96 h-auto "
        trigger={
          <button
            onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
            className="bg-maincolor/10 w-80 h-36 text-maincolor rounded-2xl border-maincolor shadow-md border-dashed border-2 text-lg mb-8"
          >
            {text}
          </button>
        }
      >
        <Modal.Content>
          <p className="text-2xl text-center mx-auto w-80 ">{title}</p>
          <p className="text-center text-deltext  font-bold  py-1  ">
            {err ? "هذا الاسم موجود بالفعل " : ""}
          </p>
          <p className="text-center text-deltext  font-bold py-1  ">
            {titen.length < 5
              ? "يجب أن تكتب أكثر من أربعة أحرف وأن تدرج رمز"
              : titar.length < 5
              ? "يجب أن تكتب أكثر من أربعة أحرف وأن تدرج رمز"
              : gtico === null
              ? "يجب أن تكتب أكثر من أربعة أحرف وان تدرج رمز"
              : isItAllArabic(titen)
              ? " تحقق من إدخال البيانات باللغة المطلوبة أو المناسبة"
              : !isItAllArabic(titar)
              ? " تحقق من إدخال البيانات باللغة المطلوبة أو المناسبة"
              : ""}
          </p>
          {/* <p className="text-center text-deltext  font-bold  py-1 ">
            {!isItAllArabic(titar)
              ? " تحقق من إدخال البيانات باللغة المطلوبة أو المناسبة
              : isItAllArabic(titen)
              ? " تحقق من إدخال البيانات باللغة المطلوبة أو المناسبة
              : ""}
          </p>
          <p className="text-center text-deltext  font-bold  py-1  ">
            {err ? "هذا الاسم موجود بالفعل " : ""}
          </p>
          <p className="text-center text-deltext  font-bold  py-1  ">
            {titen.length < 5
              ? "يجب أن تكتب أكثر من أربعة أحرف وأن تدرج رمز"
              : titar.length < 5
              ? "يجب أن تكتب أكثر من أربعة أحرف وأن تدرج رمز"
              : gtico === null
              ? "يجب أن تكتب أكثر من أربعة أحرف وأن تدرج رمز"
              : ""}
          </p> */}
          <input
            onChange={(e) => setTitelAr(e.target.value)}
            className="mx-auto w-80 h-18 flex justify-center text-right my-8 h-14 bg-[#16498614] border-b-2 border-[#878787]  hover:border-[#4B3869] focus:outline-none p-2"
            type="text"
            placeholder={placeholderar}
          />
          <input
            onChange={(e) => setTitelEn(e.target.value)}
            className="mx-auto w-80 h-18 flex justify-center  my-8 h-14 bg-[#16498614] border-b-2 border-[#878787]  hover:border-[#4B3869] focus:outline-none p-2"
            type="text"
            placeholder={placeholderen}
          />
          <label className="mx-auto w-80 h-12  flex flex-row-reverse justify-between p-2.5  border-maincolor border-2 rounded-lg">
            <div className="hidden">
              <IconModel geticon={setGeticon} />
            </div>
            <p className="break-all overflow-hidden">
              {gtico ? gtico : upload}
            </p>
            <img src={gtico ? checkmark : icon} alt="icon" />
          </label>
        </Modal.Content>
        <Modal.Actions className="border-0 bg-white p-0 m-0">
          <Button
            onClick={
              titen.length < 5
                ? ""
                : titar.length < 5
                ? ""
                : gtico === null
                ? ""
                : isItAllArabic(titen)
                ? ""
                : !isItAllArabic(titar)
                ? ""
                : handelsumbit
            }
            className={` " w-80 h-12 mb-8 mx-auto flex justify-center text-white rounded-lg   ${
              titar &&
              titen &&
              gtico &&
              !isItAllArabic(titen) &&
              isItAllArabic(titar)
                ? "bg-maincolor text-white hover:bg-maincolor/90 "
                : " bg-maincolor/50 text-black "
            } "`}
            content={content}
            loading={isLoading}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default ManagementModalAmentities;
