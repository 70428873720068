import React, { useState } from "react";
import Avatar from "../common/Avatar";
import RatingExampleHeart from "../common/rate";

const PropetesDetailsReviews = ({ use, description, rate, avatar }) => {
  return (
    <div className="mx-auto">
      <div className="flex   ">
        <div className="w-80 h-32 mx-8 my-8 ">
          <div className="flex flex-row-reverse justify-between ">
            <div className="flex ">
              <div className="mr-2">
                <h1>{use}</h1>
                <p>
                  <RatingExampleHeart max={rate} />
                </p>
              </div>
              <div className=" mr-11 w-10 h-10 object-fill rounded-full ml-2">
                {avatar ? (
                  <img
                    className="w-10 h-10 object-fill rounded-full"
                    src={avatar}
                    alt=""
                  />
                ) : (
                  <Avatar name={use} />
                )}
              </div>
            </div>
            {/* <button className="text-deltext ">حذف</button> */}
          </div>
          <p className="my-4 text-sm">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default PropetesDetailsReviews;
