import React from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import { BsPlusLg } from "react-icons/bs";
import useAxios from "../../../lib/use-axios";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import checkmark2 from "../../../../src/Assats/icon/checkmark.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RefundModel({ id, onReload }) {
  const [open, setOpen] = React.useState(false);
  const [err, SetEror] = React.useState("");
  const [state, setState] = React.useState("");
  const { run, isLoading, isError, error, data } = useAxios({ data: [] });

  const handelsumbit = () => {
    const body = {
      reservationId: id,
      cancelledBy: state,
    };
    run(authAxios.post(api.wallet.refund, body))
      .then(() => {
        setOpen(false);
        onReload();
        success();
        SetEror();
      })
      .catch((err) => {
        SetEror(err.errors?.[0].message);
        console.log(err.errors);
      });
  };

  const success = () =>
    toast.success(`  تم تسوية رسوم ربنه `, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <div>
      <ToastContainer />

      <Modal
        className="w-[25rem] h-[15rem]  rounded-2xl "
        onClose={() => {
          setOpen(false);
          onReload();
          SetEror();
        }}
        onOpen={() => setOpen(true)}
        closeIcon
        open={open}
        trigger={
          <button className=" text-white text-lg rounded-lg py-1.5 px-4 bg-maincolor hover:bg-maincolor/90">
            تسوية المرتجعات
          </button>
        }
      >
        {/* edit model here */}
        <div>
          <div className="text-center text-2xl font-bold text-maincolor py-10">
            اختر تم تسوية المرتجع من قبل من{" "}
          </div>
          <div className="flex justify-between mx-2 my-4 ">
            <Button
              className="bg-red-600  text-white  "
              onClick={() => {
                setState("cancelledByUser");
                handelsumbit();
              }}
            >
              مرتجع من المستخدم
            </Button>
            <Button
              className="bg-red-400 text-white  "
              onClick={() => {
                setState("cancelledByHost");
                handelsumbit();
              }}
            >
              مرتجع من المضيف
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default RefundModel;
