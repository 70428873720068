import React from "react";
import { Table } from "semantic-ui-react";
import RefundModel from "./Refund-model";

const UserListTransctionReservationsTable = ({ reservations, onReload }) => {
  const fixed = (e) => e?.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

  return (
    <div className="w-full">
      <Table className="border-0 border-none bg-backgroundGray pb-16">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="w-full text-maincolor text-2xl bg-backgroundGray flex border-0 border-none flex-row-reverse py-2  my-4 mr-6">
              الحجوزات
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className="">
          <Table.Row className="flex flex-row  justify-between px-16">
            <Table.Cell className=" w-full   border-0 text-center mx-auto text-headtable text-xl">
              المرتجعات
            </Table.Cell>
            <Table.Cell className=" w-full border-0 text-center mx-auto text-headtable text-xl">
              تاريخ انتهاء الحجز
            </Table.Cell>
            <Table.Cell className=" w-full  border-0 text-center mx-auto text-headtable text-xl">
              تاريخ بداية الحجز
            </Table.Cell>
            <Table.Cell className=" w-full  border-0 text-center mx-auto text-headtable text-xl">
              تاريخ الدفع
            </Table.Cell>
            <Table.Cell className=" w-full   border-0 text-center mx-auto text-headtable text-xl">
              القيمة
            </Table.Cell>
            <Table.Cell className=" w-full   border-0 text-center mx-auto text-headtable text-xl">
              الوحدة
            </Table.Cell>
          </Table.Row>
          {reservations?.map((e) => (
            <Table.Row key={e?._id} className=" flex  justify-between  px-16  ">
              <Table.Cell className=" w-full  border-0  text-center py-4  ">
                {e?.status === "accepted" ? (
                  <div>
                    <RefundModel id={e?._id} onReload={onReload} />
                  </div>
                ) : e?.status === "cancelledByUser" ? (
                  <div className="bg-red-400 text-white rounded-lg p-2 ">
                    مرتجع من المستخدم
                  </div>
                ) : e?.status === "cancelledByHost" ? (
                  <div className="bg-red-400 text-white rounded-lg p-2 ">
                    مرتجع من المضيف
                  </div>
                ) : (
                  ""
                )}
              </Table.Cell>
              <Table.Cell className=" w-full  border-0  text-center py-4">
                {new Date(e?.endDate).toLocaleDateString("en-GB")}
              </Table.Cell>
              <Table.Cell className=" w-full   text-center  mx-auto  border-0 py-4">
                {new Date(e?.startDate).toLocaleDateString("en-GB")}
              </Table.Cell>
              <Table.Cell className=" w-full   text-center  mx-auto  border-0 py-4">
                {new Date(e?.createdAt).toLocaleDateString("en-GB")}
              </Table.Cell>
              <Table.Cell className=" w-full border-0 mx-auto   text-center py-4 ">
                {fixed(e?.hostMoney)}
              </Table.Cell>
              <Table.Cell className=" w-full border-0 mx-auto   text-center  py-4">
                {e?.property.name}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default UserListTransctionReservationsTable;
