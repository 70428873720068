import React from "react";
import ManagemenTabs from "../Component/management/Management-tabs";

const Managment = () => {
  return (
    <div>
      <div className="rounded-xl shadow md:max-w-7xl mx-auto max-w-sm  ">
        <ManagemenTabs />
      </div>
    </div>
  );
};

export default Managment;
