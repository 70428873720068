import React from "react";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { Modal } from "semantic-ui-react";
import RequestsModelHost from "./requests-model-host";

function RequestsModelIdHost({
  logo,
  color,
  labol,
  onclick,
  user,
  idImagesone,
  idImagestwo,
  succ,
  err,
}) {
  const [firstOpen, setFirstOpen] = React.useState(false);

  return (
    <div className="mx-auto rounded-2xl ">
      <Modal
        closeIcon
        open={firstOpen}
        onClose={() => setFirstOpen(false)}
        onOpen={() => setFirstOpen(true)}
        className="sm:w-[40rem] h-[19rem] w-[35rem] rounded-2xl  "
        trigger={
          <div>
            <button
              className={` " flex px-2 ${color} " `}
              onClick={() => () => setFirstOpen(false)}
            >
              {logo}
              <span className="text-black text-lg">{labol}</span>
            </button>
          </div>
        }
      >
        <div className="rounded-2xl  text-2xl">
          <Modal.Content>
            <div className="flex justify-between px-10 py-8 border-b-2 ">
              <div className="flex ">
                <RequestsModelHost
                  onFirstOpen={setFirstOpen}
                  onclick={() => {
                    succ();
                    onclick();
                  }}
                  logo={<AiFillLike size={20} />}
                  color={"text-[#00B518]"}
                  bgcolor={"bg-[#00B518]"}
                  user={"المستخدم؟"}
                  labol={"اعتماد"}
                  setFirstOpen={setFirstOpen}
                />
                <RequestsModelHost
                  onclick={() => {
                    onclick();
                    err();
                  }}
                  onFirstOpen={setFirstOpen}
                  setFirstOpen={setFirstOpen}
                  logo={<AiFillDislike size={20} />}
                  color={"text-[#F65454]"}
                  bgcolor={"bg-[#F65454]"}
                  user={"المستخدم؟"}
                  labol={"رفض"}
                />
              </div>
              <p>{user}</p>
            </div>
            <div className="flex justify-between px-9 py-8">
              <img className="w-56 h-28 object-fill" src={idImagesone} alt="" />
              <img className="w-56 h-28 object-fill" src={idImagestwo} alt="" />
            </div>
          </Modal.Content>
        </div>
      </Modal>
    </div>
  );
}

export default RequestsModelIdHost;
