import React from "react";
import InputFilter from "../../Component/common/input-filter";
import UserListTable from "../../Component/user-list/User-list-table";

const Userlist = () => {
  return (
    <div className="max-w-7xl mx-auto ">
      <div className="w-full flex justify-start py-2 rounded shadow borde-0 flex-row-reverse  bg-white my-4 mr-16 ">
        <InputFilter
          transparent="true"
          name="name"
          label="...بحث بأسم المستخدم"
        />
      </div>
      <UserListTable />
    </div>
  );
};

export default Userlist;
