import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import Login from "../pages/Auth/LoginPage";
import Requests from "../pages/Requests";
import routes from "../routes";

const authlayout = () => {
  return (
    <div>
      <Switch>
        <Route path={routes.login} component={Login} />
      </Switch>
    </div>
  );
};

export default authlayout;
