import React from "react";
import { Tab, Table } from "semantic-ui-react";
import api from "../api";
import Avatar from "../Component/common/Avatar";
import InputFilter from "../Component/common/input-filter";
import PaginationFeedback from "../Component/common/Paginarion-feedback";
import RangePagination from "../Component/common/range-pagination";
import FeedbackModelmasg from "../Component/Feedback/Feedback-Model-masg";
import { authAxios } from "../config/axios-config";
import usePaginationFeedback from "../hooks/usepagination-feedback";
import useAxios from "../lib/use-axios";

const Feedback = () => {
  const { page, limit, onPageChange, onLimitChange } = usePaginationFeedback();
  const { run, isLoading, data, isError, error, totalPages } = useAxios({
    withPagination: true,
  });
  console.log(data?.pagination.totalPages);
  const [forceReload, setForceReload] = React.useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  React.useEffect(() => {
    run(authAxios.get(`${api.feedback.default}?page=${page}&perPage=${limit}`));
  }, [run, forceReload, page, limit]);
  return (
    <div className="max-w-7xl mx-auto">
      <div>
        <div>
          <Tab.Pane loading={isLoading}>
            <Table celled className="border-0 border-none bg-backgroundGray   ">
              <Table.Header
                className={`"${
                  data?.length === 0 ? "hidden" : " md:contents"
                }   hidden  " `}
              >
                <Table.Row>
                  <Table.HeaderCell className="rounded-l-xl border-0 bg-headtable/10 my-0  py-0 h-10">
                    <p className="text-end flex justify-center rounded-l-2xl text-headtable h-10  my-1 py-1">
                      رسالة ردود الأفعال
                    </p>
                  </Table.HeaderCell>
                  <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                    <p className="text-end flex justify-center text-headtable h-10  my-1 py-1">
                      رقم الهاتف
                    </p>
                  </Table.HeaderCell>
                  <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                    <p className="text-end flex justify-center text-headtable h-10  my-1 py-1">
                      {" "}
                      البريد الاكتروني
                    </p>
                  </Table.HeaderCell>
                  <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                    <p className="text-end flex justify-center text-headtable h-10  my-1 py-1">
                      {" "}
                      التاريخ
                    </p>
                  </Table.HeaderCell>
                  <Table.HeaderCell className="rounded-r-2xl border-0 bg-headtable/10 h-10  my-0 py-0">
                    <p className=" flex justify-end mr-10  text-headtable h-10  my-1 py-1">
                      {" "}
                      الاسم
                    </p>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body className="border-0">
                {data?.data.map((e) => (
                  <Table.Row
                    className="border-0  hover:bg-white rev  "
                    key={e?._id}
                  >
                    <Table.Cell className="border-0 rounded-l-xl text-center cursor-pointer ">
                      <div className="flex justify-between">
                        <FeedbackModelmasg msg={e?.message} />
                        <p className="bg-headtable/10 w-32 py-3 text-headtable md:hidden block font-bold ">
                          الآراء
                        </p>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="border-0  text-center ">
                      <div className=" ml-6 flex justify-between md:block ">
                        {e?.phoneNumber}
                        <p className="bg-headtable/10 w-32 py-3 text-headtable md:hidden block font-bold ">
                          رقم الهاتف
                        </p>
                      </div>
                    </Table.Cell>
                    <Table.Cell className=" border-0  text-center ">
                      <div className="ml-6 flex justify-between md:block  ">
                        {e.email}
                        <p className="bg-headtable/10 w-32 py-3 text-headtable md:hidden block font-bold ">
                          البريد الاكتروني
                        </p>
                      </div>
                    </Table.Cell>
                    <Table.Cell className=" border-0  text-center ">
                      <div className="ml-6 flex justify-between md:block  ">
                        {new Date(e.createdAt).toLocaleDateString("en-GB")}
                        <p className="bg-headtable/10 w-32 py-3 text-headtable md:hidden block font-bold ">
                          التاريخ
                        </p>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="rounded-r-xl border-0 text-center ">
                      <div className="flex justify-between ">
                        {e?.name}
                        <Avatar name={e.name} />
                        <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                          الاسم
                        </p>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
                {data?.length === 0 && (
                  <Table.Row textAlign="center">
                    <Table.Cell className="border-0 text-center"></Table.Cell>
                    <Table.Cell className="border-0 text-center"></Table.Cell>
                    <Table.Cell className="border-0 text-center">
                      <div className=" flex justify-between md:block  ">
                        <p className="  text-maincolor text-5xl  block font-bold py-4 ">
                          لا توجد آراء بعد
                        </p>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="border-0 text-center"></Table.Cell>
                    <Table.Cell className="border-0 text-center"></Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell
                    className="border-0 rounded-lg bg-backgroundGray"
                    colSpan="5"
                  >
                    {data?.length === 0 ? (
                      <div className="hidden ">
                        <PaginationFeedback
                          page={page}
                          totalPages={data?.pagination.totalPages}
                          onPageChange={onPageChange}
                        />
                      </div>
                    ) : (
                      <div className="row ">
                        <PaginationFeedback
                          page={page}
                          totalPages={data?.pagination.totalPages}
                          onPageChange={onPageChange}
                        />
                      </div>
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Tab.Pane>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
