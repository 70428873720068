import React from "react";
import Requests from "../pages/Requests";
import Management from "../pages/Management";
import Userlist from "../pages/user-list/Userlist";
import Massages from "../pages/Massages";
import Wallet from "../pages/Wallet";
import Settings from "../pages/Settings";
import { Switch, Route, useHistory } from "react-router-dom";
import routes from "../routes";
import Sidebar from "../Component/common/Sidebar";
import Navbar from "../Component/common/Navbar";
import Properties from "../pages/properties/Properties";
import Userdetails from "../pages/user-list/Userdetails";
import PropertiesDetail from "../pages/properties/Properties-detail";
import landingpage from "../pages/landing-page/landingpage";
import Feedback from "../pages/Feedback";

const Sidebarlayout = () => {
  const history = useHistory();

  React.useEffect(() => {
    if (!window.localStorage.getItem("token")) history.push(routes.login);
  }, []);

  React.useEffect(() => {
    document.body.classList.add("bg-backgroundGray");
    return () => document.body.classList.remove("bg-backgroundGray");
  });

  return (
    <div className="">
      <div className="">
        <Sidebar />
        <Navbar />
      </div>
      <div className=" mx-auto  lg:mr-[190px] mr-0 ">
        <Switch>
          <Route exact path={routes.landingpag} component={landingpage} />

          <Route exact path={routes.requests} component={Requests} />

          <Route
            path={routes.requestsuserdetails()}
            component={() => <Userdetails backPPath={routes.requests} />}
          />

          <Route
            path={routes.userpropertiesdetails()}
            component={() => <PropertiesDetail backPath={routes.requests} />}
          />

          <Route path={routes.management} component={Management} />

          <Route exact path={routes.userlist} component={Userlist} />

          <Route
            exact
            path={routes.userdetails()}
            component={() => <Userdetails backPPath={routes.userlist} />}
          />
          <Route
            exact
            path={routes.userdetailsproperties()}
            component={() => <PropertiesDetail backPath={routes.userdetails} />}
          />

          <Route exact path={routes.properties} component={Properties} />

          <Route
            path={routes.propertiesdetail()}
            component={() => <PropertiesDetail backPath={routes.properties} />}
          />

          <Route path={routes.massages} component={Massages} />

          <Route exact path={routes.wallet} component={Wallet} />
          <Route
            exact
            path={routes.walletuserdetails()}
            component={() => <Userdetails backPPath={routes.wallet} />}
          />
          <Route
            exact
            path={routes.walletuserdetailsProperties()}
            component={() => <PropertiesDetail />}
          />

          <Route path={routes.feedback} component={Feedback} />

          <Route path={routes.settings} component={Settings} />
        </Switch>
      </div>
    </div>
  );
};

export default Sidebarlayout;
