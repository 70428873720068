import React from "react";

const InputForm = ({ type, placeholder, label, width, value, ...props }) => {
  return (
    <div>
      <input
        className="md:w-[24rem] w-[20rem]  h-10 px-2 mt-5 outline-none border-[1px] border-maincolor rounded-lg mx-auto "
        type={type}
        placeholder={placeholder}
        value={value}
        {...props}
      />
    </div>
  );
};

export default InputForm;
