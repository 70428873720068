import React, { useState, useEffect } from "react";
import {
  BsFillArrowDownSquareFill,
  BsFillArrowUpSquareFill,
} from "react-icons/bs";
import { RiSendPlaneFill, RiRefreshFill } from "react-icons/ri";
import { IoIosRefresh } from "react-icons/io";

import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import { constructAPIQuery } from "../../utils/pagination-utils";
import Avatar from "../common/Avatar";
import routes from "../../routes";

const ENTER_KEY = 13;

const LeftSide = ({
  from,
  to,
  getOlderMessages,
  resetMessages,
  onNewMessage,
}) => {
  const { userId } = useParams();

  const { search } = useLocation();
  let apiQuery = constructAPIQuery(search);

  const [msg, setMsg] = useState("");
  const [forceReload, setForceReload] = React.useState(false);
  const validNum = (v) => /^[^-\s][a-zA-Z0-9_\s-]+$/.test(v);

  const { run, isLoading, data, isError, error, totalPages } = useAxios({
    data: [],
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onReload = React.useCallback(() => {
    resetMessages();
    setForceReload((p) => !p);
  });

  React.useEffect(() => {
    run(
      authAxios.get(api.massages.massageroom(userId) + `?from=${from}&to=${to}`)
    ).then(() => {});
  }, [run, forceReload, userId, from, to]);

  useEffect(() => {
    if (data && to < 10) {
      const divToScroll = document.getElementById("scroll-div");
      divToScroll.scrollTop = divToScroll.scrollHeight;
    }
  });

  const handelKeyDowen = (e) => {
    if (e.which === ENTER_KEY && validNum(msg)) handelSentMsg();
  };

  const handelSentMsg = () => {
    run(
      authAxios.post(api.massages.massageroom(userId), {
        message: msg,
      })
    )
      .then(() => {
        onReload();
        setMsg("");
        resetMessages();
        onNewMessage();
      })
      .catch((err) => err);
  };

  const allMasg = data?.messages;
  const user = data?.otherUser;

  return (
    <div className=" ">
      <div className="h-16 w-full flex justify-between  ">
        <Button
          loading={isLoading}
          onClick={() => onReload()}
          className="text-maincolor  bg-transparent flex justify-start justify-self-start  "
        >
          <IoIosRefresh size={25} className="p-0 m-0 " />
        </Button>
        {/* <Tab.Pane loading={isLoading}> */}
        <div className="flex justify-end mt-2 mx-8 ">
          <div className="flex flex-col justify-items-end px-4 ">
            <div className="flex justify-end sm:text-2xl text-lg ">
              {" "}
              {user && user[0]?.name}
            </div>
            <p className="text-sm flex justify-end  text-gray-500">
              {user && user[0]?.email}
            </p>
          </div>
          <div className="w-11 h-11 mt-1 mr-2 object-cover rounded-full ">
            {user && user[0]?.avatar ? (
              <img
                className="w-10 h-10  object-fill rounded-full"
                src={user && user[0]?.avatar}
                alt=""
              />
            ) : (
              <Avatar name={user && user[0]?.name} />
            )}
          </div>
        </div>
      </div>
      <div
        id="scroll-div"
        className="bg-backgroundGray w-full  h-[440px] px-8 py-8  overflow-hidden  scroll-smooth overflow-y-auto scrollbar-hide   scroll-p-0   "
      >
        <div className="  flex flex-col-reverse">
          {Array.from(Array(allMasg?.length).keys()).map((i) =>
            allMasg && allMasg[i]?.sender === userId ? (
              <div className="flex justify-start items-start cursor-pointer ">
                <div className="w-11 h-11 mt-2 mr-2 object-cover rounded-full items-start  cursor-pointer ">
                  {user && user[0]?.avatar ? (
                    <img
                      className="w-10 h-10  object-fill rounded-full  cursor-pointer"
                      src={user && user[0]?.avatar}
                      alt=""
                    />
                  ) : (
                    <Avatar name={user && user[0]?.name} />
                  )}
                </div>
                <div className=" bg-gray-300 max-w-xs  break-all text-sm rounded-t-xl rounded-r-xl  items-start   cursor-pointer  px-4 my-2 py-2">
                  {allMasg && allMasg[i]?.message}
                </div>
              </div>
            ) : (
              <div className=" bg-maincolor max-w-xs  break-all  text-sm rounded-t-xl rounded-l-xl text-white flex justify-end  self-end items-end text-right my-2 px-4 py-2">
                {allMasg && allMasg[i]?.message}
              </div>
            )
          )}

          <button
            onClick={getOlderMessages}
            className="text-maincolor/60 w-full flex justify-center"
          >
            <p className="my-2 mx-2 mb-16"> الرسائل القديمة</p>
            <BsFillArrowUpSquareFill size={30} />
          </button>
        </div>
      </div>
      <div className="bg-white w-full  h-16 flex flex-row-reverse justify-between">
        <input
          onKeyUp={handelKeyDowen}
          onChange={(event) => setMsg(event.target.value)}
          className="border-0  w-full h-8  my-4 focus:outline-none text-right px-2 rounded-xl  "
          type="text"
          placeholder="...اكتب رسالتك"
          value={msg}
        />
        <Button
          loading={isLoading}
          className="border-0 shadow-none my-2 "
          type="submit"
          onClick={validNum(msg) ? handelSentMsg : ""}
          basic
        >
          <RiSendPlaneFill className=" text-maincolor " size={30} />
        </Button>
      </div>
      {/* </Tab.Pane> */}
    </div>
  );
};

export default LeftSide;
