import React from "react";
import { Flag, Tab } from "semantic-ui-react";
import "../../../../src/Assats/style/user-details-tabs.css";
import LeftSide from "../../message/left-side-message";
import TransctionTabe from "./Transction-tabe";
import BasicInfo from "./user-list-details-basic-info";
import UserDetailsListings from "./user-list-details-listings";
import UserListTransctionTable from "./User-list-transction-resevation-table";

const panes = ({ messagesTo, getOlderMessages, resetMessages }) => [
  {
    menuItem: "معلومات أساسية",
    render: () => (
      <Tab.Pane attached={false}>
        <div className=" py-8 mx-auto w-full  ">
          <BasicInfo />
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: "الوحدات",
    render: () => (
      <Tab.Pane attached={false}>
        <div>
          <UserDetailsListings />
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: "رسائل",
    render: () => (
      <Tab.Pane className=" mx-24 md:mx-0">
        <div className="w-full rounded-xl shadow   ">
          <LeftSide
            from={0}
            to={messagesTo}
            getOlderMessages={getOlderMessages}
            resetMessages={resetMessages}
          />
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: "المعاملات ",
    render: () => (
      <Tab.Pane className=" mx-24 md:mx-0">
        <div className="w-full rounded-xl shadow   ">
          <TransctionTabe />
        </div>
      </Tab.Pane>
    ),
  },
];

const UserDetailsTab = () => {
  const [messagesTo, setMessagesTo] = React.useState(9);

  const getOlderMessages = () => {
    setMessagesTo((prev) => prev + 9);
  };

  const resetMessages = () => setMessagesTo(9);

  return (
    <div>
      <div className="sm:hidden md bg-backgroundGray   ">
        <Tab
          menu={{ fluid: false, vertical: true }}
          panes={panes({ messagesTo, getOlderMessages, resetMessages })}
        />
      </div>
      <div className="sm:block hidden bg-backgroundGray ">
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes({ messagesTo, getOlderMessages, resetMessages })}
        />
      </div>
    </div>
  );
};

export default UserDetailsTab;
