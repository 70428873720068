import React, { useState, useSyncExternalStore } from "react";
import { Button, Modal } from "semantic-ui-react";
import { authAxios, axios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import checkmark from "../../../src/Assats/icon/checkmark.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}
function ManagementCatagoriesEditModel({
  text,
  title,
  content,
  placeholderar,
  placeholderen,
  namevalar,
  namevalen,
  pic,
  upload,
  icon,
  endpont,
  endpointimg,

  reload,
}) {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;

  const { run, isLoading, isError, error, data } = useAxios({ data: [] });

  const [photoname, setPhotoname] = useState(null);
  const [photo, setPhoto] = useState(null);

  const [titar, setTitelAr] = useState(namevalar);
  const [titen, setTitelEn] = useState(namevalen);

  const isItAllArabic = (s) =>
    !/[^\u0600-\u06FF\u0020-\u0040\u005B-\u0060\u007B-\u007E]/.test(s);

  const imgCheker = (img) =>
    /\.(jpg|jpeg|png|gif|tiff|psd|esp|ai|raw)$/.test(img);

  const success = () =>
    toast.success(`  تم ${content}   `, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handelsumbit = () => {
    const body = {
      name_ar: titar,
      name_en: titen,
    };
    const formData = new FormData();
    formData.append("image", photo);

    run(authAxios.put(endpointimg, formData))
      .then(() => {
        success();
        dispatch({ type: "CLOSE_MODAL" });
        reload();
        setPhotoname(null);
        setPhoto(null);
      })
      .catch((err) => {
        console.log(err.errors);
      });

    run(authAxios.put(endpont, body))
      .then((res) => {
        // success();
        dispatch({ type: "CLOSE_MODAL" });
        reload();
        setPhotoname(null);
        setPhoto(null);
      })
      .catch((err) => {
        console.log(err.errors);
      });
  };

  return (
    <div className="mx-auto ">
      <ToastContainer />
      <Modal
        dimmer={dimmer}
        closeIcon
        open={open}
        onClose={() =>
          dispatch({ type: "CLOSE_MODAL" }, setPhotoname(null), reload())
        }
        className="w-96 h-auto "
        trigger={
          <button
            onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
          >
            {text}
          </button>
        }
      >
        <Modal.Content>
          <p className="text-2xl text-center mx-auto w-80">{title}</p>
          <p className="text-center text-deltext  font-bold py-1  ">
            {titen.length < 5
              ? "يجب أن تكتب أكثر من أربعة أحرف وأن تدرج صورة"
              : titar.length < 5
              ? "يجب أن تكتب أكثر من أربعة أحرف وأن تدرج صورة"
              : photoname === null
              ? "يجب أن تكتب أكثر من أربعة أحرف وأن تدرج صورة"
              : isItAllArabic(titen)
              ? " تحقق من إدخال البيانات باللغة المطلوبة أو المناسبة"
              : !isItAllArabic(titar)
              ? " تحقق من إدخال البيانات باللغة المطلوبة أو المناسبة"
              : imgCheker(photoname)
              ? " "
              : "تحقق من أدراج ملف من نوع صورة "}
            {/* {imgCheker(photoname) ? "" : "تحقق من أدراج ملف من نوع صورة "} */}
          </p>

          <input
            onChange={(e) => setTitelAr(e.target.value)}
            className="mx-auto w-80 h-18 flex justify-center text-right my-8 h-14 bg-[#16498614] border-b-2 border-[#878787]  hover:border-[#4B3869] focus:outline-none p-2"
            type="text"
            value={titar}
            placeholder={placeholderar}
          />
          <input
            onChange={(e) => setTitelEn(e.target.value)}
            className="mx-auto w-80 h-18 flex justify-center  my-8 h-14 bg-[#16498614] border-b-2 border-[#878787]  hover:border-[#4B3869] focus:outline-none p-2"
            type="text"
            value={titen}
            placeholder={placeholderen}
          />
          <img
            src={pic}
            className="my-5 w-80 h-40 object-cover mx-auto "
            alt=""
          />
          <label className="mx-auto w-80 h-12  flex flex-row-reverse justify-between p-2.5  border-maincolor border-2 rounded-lg">
            <p className="break-all overflow-hidden">
              {photoname ? photoname : upload}
            </p>
            <img
              className="mr-2"
              src={photoname ? checkmark : icon}
              alt="icon"
            />
            <input
              onChange={(e) => {
                setPhoto(e.target.files[0]);
                setPhotoname(e.target.files[0].name);
              }}
              className="hidden"
              type="file"
            />
          </label>
        </Modal.Content>
        <Modal.Actions className="border-0 bg-white p-0 m-0">
          <Button
            onClick={
              titen.length < 5
                ? ""
                : titar.length < 5
                ? ""
                : (photo || pic) === null
                ? ""
                : isItAllArabic(titen)
                ? ""
                : !isItAllArabic(titar)
                ? ""
                : imgCheker(photoname)
                ? ""
                : handelsumbit
            }
            className={` " w-80 h-12 mx-auto flex justify-center text-white rounded-lg mb-8  ${
              titar &&
              titen &&
              (photo || pic) &&
              !isItAllArabic(titen) &&
              isItAllArabic(titar)
                ? "bg-maincolor text-white hover:bg-maincolor/90 "
                : " bg-maincolor/50 text-black "
            } "`}
            content={content}
            loading={isLoading}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default ManagementCatagoriesEditModel;
