import React from "react";
import pic from "../../../../src/pages/landing-page/assets/pic.png";
import { useLanguage } from "../context/Language-Context";
import { content } from "../localization/content";

const AboutUs = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  return (
    <div id="About_us" className="w-full pt-24 ">
      <div className="max-w-7xl mx-auto">
        <div className="grid gap-10 mx-6  md:grid-cols-2 grid-cols-1">
          <div>
            <img
              className="md:w-[34rem] md:h-[20rem] w-full h-[15rem] rounded-lg my-8 mx-auto"
              src={pic}
              alt=""
            />
          </div>
          <div>
            <h1 className="font-bold text-3xl pb-6">{langContent.AboutUs}</h1>
            <div>
              <p className="text-xl font-normal py-4">
                {langContent.aboutone} <br></br>
                {langContent.abouttow} <br></br>
                {langContent.abutthree} <br></br>
                {langContent.abutfore}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
