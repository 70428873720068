import React, { useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { AiFillDislike, AiFillLike, AiOutlineStop } from "react-icons/ai";
import { BiRightArrowAlt } from "react-icons/bi";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import api from "../../api";
import PropertesDetailsAmenitiesRules from "../../Component/properties/propertes-details-amenities-rules";
import PropertesDetailsPhoto from "../../Component/properties/propertes-details-photo";
import PropertesDetailsPrice from "../../Component/properties/propertes-details-price";
import PropertesDetailsTitle from "../../Component/properties/propertes-details-title";
import PropetesDetailsReviews from "../../Component/properties/propetes-details-reviews";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import routes from "../../routes";
import Reviews from "../../Component/properties/Reviews";
import Avatar from "../../Component/common/Avatar";
import RequestsModelHost from "../../Component/requests/requests-model-host";
import PendingApprovalModal from "../../Component/requests/pending-approval-model";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PropertiesDetail = ({ backPath }) => {
  const [forceReload, setForceReload] = React.useState(false);
  // const [forceCloseModal, setForceCloseModal] = React.useState({
  //   close: false,
  //   state: "",
  // });

  const [secOpen, setSecOpen] = React.useState(false);

  const history = useHistory();
  const { propertyId, verification } = useParams();

  const { pathname } = useLocation();
  const { run, isLoading, isError, error, data } = useAxios({ data: {} });

  // console.log(propertyId);

  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  useEffect(() => {
    run(authAxios.get(api.admin.properties.default + propertyId));
  }, [propertyId, run, forceReload]);

  const success = () =>
    toast.success(" تم اعتماد هذه الوحدة", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const err = () =>
    toast.error(" تم رفض هذه الوحدة", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const idAccept = (c) => {
    authAxios.put(api.admin.properties.default + c + "/accept").then(() => {
      onReload();
      setSecOpen(false);
    });
  };
  const idRejected = (c) => {
    authAxios.put(api.admin.properties.default + c + "/reject").then(() => {
      onReload();
      setSecOpen(false);
    });
  };

  const containerStyle = {
    width: "",
    height: "11rem",
    border: "2px solid white",
    borderRadius: "12px",
    position: "relative",
  };

  const center = {
    lat: data?.location?.latitude,
    lng: data?.location?.longitude,
  };
  const [showFullDescription, setFullDescription] = React.useState(false);
  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const description = showFullDescription
    ? data?.host?.description
    : data?.host?.description?.slice(0, 40);

  return (
    <div className="h-[108rem] max-w-7xl mx-auto text-lg w-full ">
      <ToastContainer />
      <Tab.Pane loading={isLoading}>
        <div className="w-full h-16 flex flex-row-reverse justify-between borde-0 my-4 rounded-xl shadow  bg-white px-10">
          <button
            className="text-headtable font-bold  flex mt-5"
            onClick={() => {
              // history.push(backPath);
              history.goBack();
            }}
          >
            <p>رجوع</p>
            <BiRightArrowAlt size={25} />
          </button>
          {pathname.startsWith(routes.requests) ? (
            <div
              className={` ${
                data?.status === "rejected"
                  ? " hidden"
                  : data?.status === "published"
                  ? "hidden"
                  : ""
              } py-4 `}
            >
              <button>
                {verification === "verified" ? (
                  <RequestsModelHost
                    isLoading={isLoading}
                    onclick={() => {
                      success();
                      idAccept(propertyId);
                    }}
                    logo={<AiFillLike size={20} />}
                    color={"text-greenz"}
                    bgcolor={"bg-greenz"}
                    user={" القائمة؟"}
                    labol={"اعتماد"}
                    onReload={onReload}
                  />
                ) : (
                  <PendingApprovalModal
                    logo={<AiFillLike size={20} />}
                    color={"text-greenz"}
                    labol={"اعتماد"}
                  />
                )}
              </button>
              <button>
                <RequestsModelHost
                  isLoading={isLoading}
                  onclick={() => {
                    err();
                    idRejected(propertyId);
                  }}
                  logo={<AiFillDislike size={20} />}
                  color={"text-redz"}
                  bgcolor={"bg-redz"}
                  user={" القائمة؟"}
                  labol={"رفض"}
                />
              </button>
            </div>
          ) : (
            <button className="text-deltext  flex ">
              {/* <p className="font-bold px-2 mt-6">تعليق المستخدم</p>
              <AiOutlineStop className="mt-5 font-bold " size={25} /> */}
            </button>
          )}
        </div>

        {/* photot */}

        <div className="w-full h-auto rounded-xl shadow  bg-white">
          <h1 className="text-3xl text-maincolor text-right mx-6 my-8 font-medium">
            صور
          </h1>
          <div>
            <PropertesDetailsPhoto pic={data?.pictures && data?.pictures} />
          </div>
        </div>

        {/* details */}

        <div className="my-8 rounded-xl shadow  h-auto bg-white">
          <PropertesDetailsTitle
            title={data?.name}
            comp={data?.components}
            min={data?.minStay}
            max={data?.maxStay}
            des={data?.description}
          />
        </div>

        {/* Price per night */}

        <div className="h-auto rounded-xl shadow  px-8  bg-white">
          <PropertesDetailsPrice
            price={data?.dayPrice}
            totalprice={data?.vacationDayPrice}
          />
        </div>

        {/* Amenities and  House rules */}

        <div className="h-auto rounded-xl shadow my-8 w-full  bg-white">
          <PropertesDetailsAmenitiesRules data={data} />
        </div>

        {/* Reviews*/}

        <Reviews id={data?.host?._id} />

        {/* location and host */}

        {/* location*/}
        <div className="md:flex justify-between mb-24 grid grid-cols-1 my-8 md:my-0  md:w-auto    ">
          <div
            className="  rounded-3xl w-[100%]  md:w-[38rem] 
            "
          >
            {/* <div className="   bg-gradient-to-b from-gray-400 to-transparent text-2xl text-white text-right py-3 px-24  absolute z-[100] md:w-[40rem] w-full h-[11rem] rounded-3xl cursor-pointer hover:hidden  ">
              الموقع
            </div> */}
            <LoadScript googleMapsApiKey="AIzaSyBumLHVDT7VaepXboyFZwAYMawLPpa_9Vs">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                Marker
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </div>

          {/* host */}
          <div className="md:w-[38rem] w-full  h-auto rounded-xl shadow text-right py-8 px-8 bg-white my-6 md:my-0">
            <div className="pb-8 text-2xl text-maincolor w-full">
              <p>المضيف</p>
            </div>
            <div className="flex justify-end w-full h-full ">
              <div className="mr-4 w-full">
                <h1 className="w-full">{data?.host?.name} </h1>
                <p className="text-sm text-gray-500 ml-6  ">
                  <button onClick={showFullDescriptionHandler}>
                    {description}
                    <span className="text-black font-medium">
                      أقراء
                      {showFullDescription ? " اقل" : "  المزيد "}
                    </span>
                  </button>
                </p>
              </div>
              <div className="  w-10 h-10 object-fill rounded-full ">
                {data?.host?.avatar ? (
                  <img
                    className=" object-fill rounded-full"
                    src={data?.host?.avatar}
                    alt=""
                  />
                ) : (
                  <Avatar name={data?.host?.name} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Tab.Pane>
    </div>
  );
};
export default React.memo(PropertiesDetail);
