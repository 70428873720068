import React from "react";

const useLocalstorage = (
  key,
  defaultValue = "",
  serialize = (val) => val,
  deserialize = (val) => val
) => {
  const [state, setState] = React.useState(
    () => window.localStorage.getItem(key) || defaultValue
  );

  React.useEffect(() => {
    window.localStorage.setItem(key, serialize(state));
  }, [key, state]);

  return [deserialize(state), setState];
};

export default useLocalstorage;
