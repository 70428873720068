import React from "react";
import { Pagination } from "semantic-ui-react";

const PaginationFeedback = ({ page, totalPages, onPageChange }) => {
  return (
    <div className="">
      <Pagination
        secondary
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        activePage={page}
        totalPages={totalPages}
        onPageChange={(e, data) => onPageChange(data.activePage)}
      />
    </div>
  );
};

export default PaginationFeedback;
