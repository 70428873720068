import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TabPane } from "semantic-ui-react";
import api from "../../../api";
import { authAxios } from "../../../config/axios-config";
import useAxios from "../../../lib/use-axios";
import UserListTransctionReservationsTable from "./User-list-transction-resevation-table";
import UserListTransctionSettlementsTable from "./User-list-transction-settlements-table";
import UserListTransctionEarnings from "./UserListTransctionEarnings";

const TransctionTabe = () => {
  const [forceReload, setForceReload] = React.useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  const { userId } = useParams();
  const { run, isLoading, isError, error, data } = useAxios({ data: {} });
  console.log(data);

  useEffect(() => {
    run(authAxios.get(api.user.usertranction(userId)));
  }, [run, userId, forceReload]);
  return (
    <div>
      {console.log(data?.data?.reservations.length === 0 ? true : false)}
      {data?.data?.reservations.length === 0 ? (
        <p className="  text-maincolor text-5xl  text-center font-bold py-36 ">
          لا يوجد مُعاملات بعد
        </p>
      ) : (
        <TabPane loading={isLoading}>
          <UserListTransctionEarnings
            earning={data?.data?.earnings}
            onReload={onReload}
          />
          <UserListTransctionReservationsTable
            reservations={data?.data?.reservations}
            onReload={onReload}
          />
          <UserListTransctionSettlementsTable
            settlements={data?.data?.settlements}
          />
        </TabPane>
      )}
    </div>
  );
};

export default TransctionTabe;
