import React from "react";
import { Icon, Menu, Tab, Table } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import { Link } from "react-router-dom";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import RangePagination from "../common/range-pagination";
import { useLocation } from "react-router-dom";
import { constructAPIQuery } from "../../utils/pagination-utils";
import useAxios from "../../lib/use-axios";
import Avatar from "../common/Avatar";
import { IoIosEye } from "react-icons/io";

const PropertiesTable = () => {
  const [forceReload, setForceReload] = React.useState(false);

  const { search } = useLocation();
  const apiQuery = constructAPIQuery(search);

  const { run, isLoading, data, isError, error, totalPages } = useAxios({
    withPagination: true,
  });

  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  React.useEffect(() => {
    run(authAxios.get(`${api.admin.properties.all}${apiQuery}`));
  }, [run, forceReload, apiQuery]);

  return (
    <div>
      <Tab.Pane loading={isLoading}>
        <Table celled className="border-0 border-none bg-backgroundGray  ">
          <Table.Header className="hidden md:contents">
            <Table.Row>
              <Table.HeaderCell className="rounded-l-xl border-0 bg-headtable/10">
                <p className=" text-center rounded-l-2xl text-headtable ">
                  معاينة
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className="border-0 bg-headtable/10">
                <p className=" text-center  text-headtable ">نشرت بواسطة</p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10">
                <p className=" text-center text-headtable">تاريخ اخر تعديل </p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10">
                <p className=" text-center text-headtable"> التاريخ المدرج</p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10">
                <p className=" text-center text-headtable">مدينة</p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10">
                <p className=" text-center text-headtable">النوع</p>
              </Table.HeaderCell>
              <Table.HeaderCell className="rounded-r-2xl border-0 bg-headtable/10">
                <p className=" text-center text-headtable"> الاسم</p>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body className="border-0">
            {data?.map((e) => (
              <Table.Row className="border-0">
                <Table.Cell className="rounded-l-xl border-0 text-center  ">
                  <Link to={routes.propertiesdetail(e._id)}>
                    <div className="ml-6 flex justify-between md:block  ">
                      <button>
                        <IoIosEye className="mx-auto" size={25} />
                      </button>
                      <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                        معاينة
                      </p>
                    </div>
                  </Link>
                </Table.Cell>
                <Table.Cell className=" border-0  text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {e?.host?.name}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      نشرت
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className=" border-0  text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {new Date(e?.updatedAt).toLocaleDateString("en-GB")}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      التاريخ المدرج
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className=" border-0  text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {new Date(e?.createdAt).toLocaleDateString("en-GB")}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      تاريخ اخر تعديل
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className=" border-0  text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {e?.mainCity?.name?.ar}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      مدينة
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className=" border-0  text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {e?.category?.name?.ar}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      النوع
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="rounded-r-xl border-0  text-center ">
                  <Link
                    className="ml-6 flex justify-between md:block"
                    to={routes.propertiesdetail(e._id)}
                  >
                    {e?.name}
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      الاسم
                    </p>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell
                className="border-0 rounded-lg bg-backgroundGray"
                colSpan="7"
              >
                <div className="row ">
                  <RangePagination totalPages={totalPages} />
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Tab.Pane>
    </div>
  );
};

export default PropertiesTable;
