import React from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import { BsPlusLg } from "react-icons/bs";
import useAxios from "../../../lib/use-axios";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import checkmark2 from "../../../../src/Assats/icon/checkmark.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TransctionModel({ table, pendingEarnings, id, onReload }) {
  const [open, setOpen] = React.useState(false);
  const [amount, SetAmount] = React.useState("");
  const [doc, SetDoc] = React.useState(null);
  const [docname, SetDocname] = React.useState(null);
  const [err, SetEror] = React.useState("");

  // const fixed = (e) => e?.toFixed(2);
  const fixed = (e) => e?.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  const { run, isLoading, isError, error, data } = useAxios({ data: [] });

  const handelsumbit = () => {
    const formData = new FormData();
    formData.append("host", id);
    formData.append("amount", amount);
    formData.append("attachment", doc);

    run(authAxios.post(api.wallet.settlement, formData))
      .then(() => {
        setOpen(false);
        SetAmount("");
        SetDoc("");
        SetDocname("");
        onReload();
        success();
        SetEror();
      })
      .catch((err) => {
        SetEror(err.errors?.[0].message);
        console.log(err.errors);
      });
  };

  const success = () =>
    toast.success(`  تم تسوية رسوم ربنه `, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <div>
      <ToastContainer />
      <Modal
        className="w-[38rem]  rounded-2xl "
        onClose={() => {
          setOpen(false);
          SetDocname("");
          SetAmount("");
          SetDoc("");
          onReload();
          SetEror();
        }}
        onOpen={() => setOpen(true)}
        closeIcon
        open={open}
        trigger={
          <button
            className={`w-20 h-8  ${
              table === true
                ? "bg-white text-maincolor text-lg "
                : "bg-maincolor text-white  rounded-lg hover:bg-maincolor/90"
            }  `}
          >
            تسوية
          </button>
        }
      >
        {/* edit model here */}
        <div className="flex flex-col justify-between mx-auto gap-8">
          <h1 className="mx-auto pt-8 text-2xl font-bold text-maincolor">
            تسوية المدفوعات
          </h1>

          <p className="text-center text-deltext  font-bold   ">
            {pendingEarnings < 0
              ? `هذا المضيف عليه مديونية بقيمة ${Math.abs(pendingEarnings)}`
              : err ===
                "The given amount is greated than the host's pending request, or below zero."
              ? `${fixed(pendingEarnings)} الحد الاقصي للتسوية`
              : ""}
          </p>
          <input
            className="w-80 h-14 mx-auto bg-[#16498614]  border-b-2 border-maincolor focus:outline-none pr-2  text-right"
            placeholder="أرباح معلقة"
            type="text"
            value={fixed(pendingEarnings)}
          />
          <input
            className="w-80 h-14 mx-auto bg-[#16498614]  border-b-2 border-maincolor focus:outline-none pr-2  text-right"
            placeholder="مبلغ الافراج"
            type="text"
            onChange={(e) => SetAmount(e.target.value)}
          />
          <label className="mx-auto w-80 h-14 flex justify-between  border-2 border-maincolor rounded-xl ">
            <p className="mt-4 ml-8 ">
              {docname ? (
                <img src={checkmark2} alt="" />
              ) : (
                <BsPlusLg size={15} />
              )}
            </p>
            <p className="mx-auto pt-3  text-maincolor font-bold text-center break-all overflow-hidden">
              {docname ? docname : " تحميل المستند (اختياري)"}
            </p>
            <input
              onChange={(e) => {
                SetDoc(e.target.files[0]);
                SetDocname(e.target.files[0].name);
              }}
              className="mx-auto hidden"
              type="file"
            />
          </label>
          <Button
            loading={isLoading}
            onClick={pendingEarnings > 0 ? handelsumbit : ""}
            className={
              amount || doc || !pendingEarnings < 0
                ? "mx-auto mb-8 w-80 h-14 bg-maincolor text-white hover:bg-maincolor/90"
                : " mx-auto mb-8 w-80 h-14 bg-maincolor/50 text-black "
            }
          >
            تسوية
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default TransctionModel;
