import React from "react";
import { Icon, Menu, Pagination, Tab, Table } from "semantic-ui-react";
import routes from "../../routes";
import { Link } from "react-router-dom";
import api from "../../api";
import { IoIosEye } from "react-icons/io";
import { AiOutlineStop } from "react-icons/ai";
import RangePagination from "../common/range-pagination";
import { useLocation } from "react-router-dom";
import { constructAPIQuery } from "../../utils/pagination-utils";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import Avatar from "../common/Avatar";
import Userdetails from "../../pages/user-list/Userdetails";

const UserListTable = () => {
  const [forceReload, setForceReload] = React.useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  const { search } = useLocation();
  const apiQuery = constructAPIQuery(search);
  const query = new URLSearchParams(search);
  const searchedName = query.get("name");

  const { run, isLoading, data, isError, error, totalPages } = useAxios({
    withPagination: true,
  });

  React.useEffect(() => {
    run(authAxios.get(`${api.user.userlist}${apiQuery}`));
  }, [run, forceReload, apiQuery]);

  return (
    <Tab.Pane loading={isLoading}>
      <Table celled className="border-0 border-none bg-backgroundGray   ">
        <Table.Header
          className={`"${
            data?.length === 0 ? "hidden" : " md:contents"
          }   hidden  " `}
        >
          <Table.Row>
            {/* <Table.HeaderCell className="rounded-l-xl border-0 bg-headtable/10 my-0  py-0 h-10">
              <p className="text-center rounded-l-2xl text-headtable h-10  my-1 py-1 ">
                الحالة
              </p>
            </Table.HeaderCell> */}
            <Table.HeaderCell className="border-0 bg-headtable/10 my-0  py-0 h-10">
              <p className="text-center  rounded-l-2xl text-headtable h-10  my-1 py-1 ">
                معاينة
              </p>
            </Table.HeaderCell>
            <Table.HeaderCell className="border-0 bg-headtable/10 my-0  py-0 h-10">
              <p className="text-center  rounded-l-2xl text-headtable h-10  my-1 py-1 ">
                الوحدات المؤرشفة
              </p>
            </Table.HeaderCell>
            <Table.HeaderCell className=" border-0 bg-headtable/10 my-0  py-0 h-10">
              <p className="text-center  text-headtable h-10  my-1 py-1">
                الوحدات المعلقة
              </p>
            </Table.HeaderCell>
            <Table.HeaderCell className=" border-0 bg-headtable/10 my-0  py-0 h-10">
              <p className="text-center  text-headtable h-10  my-1 py-1">
                الوحدات الحالية
              </p>
            </Table.HeaderCell>
            <Table.HeaderCell className=" border-0 bg-headtable/10 my-0  py-0 h-10">
              <p className="text-center text-headtable h-10  my-1 py-1">
                تاريخ الانضمام
              </p>
            </Table.HeaderCell>
            <Table.HeaderCell className="rounded-r-2xl border-0 bg-headtable/10 my-0  py-0 h-10">
              <p className="text-center  text-headtable h-10  my-1 py-1">
                {" "}
                الاسم
              </p>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className="border-0">
          {data?.map((e) => (
            <Table.Row className="border-0 hover:bg-white">
              <Table.Cell className="border-0 text-center ">
                <div className="ml-6 flex justify-between">
                  <Link className="ml-3 " to={routes.userdetails(e._id)}>
                    <button>
                      <IoIosEye className="mx-auto" size={25} />
                    </button>
                  </Link>
                  <p className="bg-headtable/10 w-36 py-3 text-headtable md:hidden block font-bold ">
                    معاينة
                  </p>
                </div>
              </Table.Cell>
              <Table.Cell className=" border-0  text-center ">
                <div className="ml-6 flex justify-between md:block">
                  {e?.properties?.archived_properties}
                  <p className="bg-headtable/10 w-36 py-3 text-headtable md:hidden block font-bold ">
                    الوحدات المؤرشفة
                  </p>
                </div>
              </Table.Cell>
              <Table.Cell className=" border-0  text-center ">
                <div className="ml-6 flex justify-between md:block">
                  {e?.properties?.pending_properties}
                  <p className="bg-headtable/10 w-36 py-3 text-headtable md:hidden block font-bold ">
                    {" "}
                    الوحدات المعلقة
                  </p>
                </div>
              </Table.Cell>
              <Table.Cell className=" border-0  text-center ">
                <div className="ml-6 flex justify-between md:block  ">
                  {e?.properties?.current_properties}
                  <p className="bg-headtable/10 w-36 py-3 text-headtable md:hidden block font-bold ">
                    الوحدات الحالية
                  </p>
                </div>
              </Table.Cell>
              <Table.Cell className=" border-0  text-center ">
                <div className="flex justify-between ml-6">
                  {new Date(e.createdAt).toLocaleDateString("en-GB")}

                  <p className="bg-headtable/10 w-36 py-3 text-headtable md:hidden block font-bold ">
                    {" "}
                    تاريخ الانضمام
                  </p>
                </div>
              </Table.Cell>
              <Table.Cell className="rounded-r-xl border-0  text-center ">
                <Link
                  className="ml-3 flex justify-between "
                  to={routes.userdetails(e._id)}
                >
                  <Link className="ml-3 " to={routes.userdetails(e._id)}>
                    {e.name}
                  </Link>
                  <div className=" mr-11 w-10 h-10 object-fill rounded-full ml-2">
                    {e.avatar ? (
                      <img
                        className="w-10 h-10 object-fill rounded-full"
                        src={e.avatar}
                        alt=""
                      />
                    ) : (
                      <Avatar name={e.name} />
                    )}
                  </div>
                  <p className="bg-headtable/10 w-36 py-3 text-headtable md:hidden block font-bold ">
                    الاسم
                  </p>
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
          {data?.length === 0 && !searchedName && (
            <Table.Row textAlign="center">
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center">
                <div className=" flex justify-between md:block  ">
                  <p className="  text-maincolor text-5xl  block font-bold py-4 ">
                    لا يوجد مستخدمين بعد
                  </p>
                </div>
              </Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
            </Table.Row>
          )}
          {data?.length === 0 && searchedName && (
            <Table.Row textAlign="center">
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center">
                <div className=" flex justify-between md:block  ">
                  <p className="  text-maincolor text-5xl  block font-bold py-4 ">
                    لا يوجد مستخدمين بهذا الإسم
                  </p>
                </div>
              </Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
              <Table.Cell className="border-0 text-center"></Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell
              className="border-0 rounded-lg bg-backgroundGray"
              colSpan="7"
            >
              <div className="row ">
                <RangePagination totalPages={totalPages} />
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Tab.Pane>
  );
};
export default UserListTable;
