import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination, Tab, Table } from "semantic-ui-react";
import routes from "../../routes";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import RequestsModelHost from "./requests-model-host";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { IoIosEye } from "react-icons/io";
import useAxios from "../../lib/use-axios";
import { useLocation } from "react-router-dom";
import RangePagination from "../common/range-pagination";
import { constructAPIQuery } from "../../utils/pagination-utils";
import InputFilter from "../common/input-filter";
import RequestsModelIdHost from "./requests-model-id-host";
import Avatar from "../common/Avatar";
import Userdetails from "../../pages/user-list/Userdetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TableHostRequests = () => {
  const [forceReload, setForceReload] = React.useState(false);

  const { search } = useLocation();
  const apiQuery = constructAPIQuery(search);
  const query = new URLSearchParams(search);
  const searchedName = query.get("name");

  const { run, isLoading, data, isError, error, totalPages } = useAxios({
    withPagination: true,
  });

  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  const success = () =>
    toast.success(" تم اعتماد هذا المستخدم", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const err = () =>
    toast.error(" تم رفض هذا المستخدم", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const idImgVerified = (c) => {
    authAxios.post(api.admin.user(c), { verification: "verified" }).then(() => {
      onReload();
    });
  };
  const idImgRejected = (c) => {
    authAxios.post(api.admin.user(c), { verification: "rejected" }).then(() => {
      onReload();
    });
  };

  React.useEffect(() => {
    run(authAxios.get(`${api.admin.host}${apiQuery}`));
  }, [run, forceReload, apiQuery]);
  return (
    <div>
      <ToastContainer />
      <Tab.Pane loading={isLoading}>
        <InputFilter name="name" label="... بحث باسم المستخدم " />
        <Table celled className="border-0 border-none bg-backgroundGray   ">
          <Table.Header
            className={`"${
              data?.length === 0 ? "hidden" : " md:contents"
            }   hidden  " `}
          >
            <Table.Row>
              <Table.HeaderCell className="rounded-l-xl border-0 bg-headtable/10 my-0  py-0 h-10">
                <p className="text-end flex justify-center rounded-l-2xl text-headtable h-10  my-1 py-1">
                  معاينة
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className="text-end flex justify-center text-headtable h-10  my-1 py-1">
                  الحالة
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className="text-end flex justify-center text-headtable h-10  my-1 py-1">
                  {" "}
                  الوحدات المعلقة
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className=" border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className="text-end flex justify-center text-headtable h-10  my-1 py-1">
                  {" "}
                  تاريخ الطلب
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className="rounded-r-2xl border-0 bg-headtable/10 h-10  my-0 py-0">
                <p className=" flex justify-end mr-10  text-headtable h-10  my-1 py-1">
                  {" "}
                  الاسم
                </p>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body className="border-0">
            {data?.map((e) => (
              <Table.Row
                className="border-0  hover:bg-white rev  "
                key={e?._id}
              >
                <Table.Cell className="border-0 rounded-l-xl text-center ">
                  <div className=" ml-6 flex justify-between md:block ">
                    <button>
                      <RequestsModelIdHost
                        succ={() => success()}
                        err={() => err()}
                        logo={<IoIosEye className="mx-auto" size={25} />}
                        onclick={() => idImgVerified(e._id)}
                        user={e?.name}
                        idImagesone={e?.idImages[0]?.url}
                        idImagestwo={e?.idImages[1]?.url}
                      />
                    </button>
                    <p className="bg-headtable/10 w-32 py-3 text-headtable md:hidden block font-bold ">
                      معاينة
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="border-0  text-center ">
                  <div className=" ml-6 flex justify-between md:block ">
                    <button>
                      <RequestsModelHost
                        onclick={() => {
                          success();
                          idImgVerified(e._id);
                        }}
                        isLoading={isLoading}
                        onReload={onReload}
                        logo={<AiFillLike size={20} />}
                        color={"text-greenz"}
                        bgcolor={"bg-greenz"}
                        user={"المستخدم؟"}
                        pragraph={".هذا سيمكنه من نشر الوحدات"}
                        labol={"    اعتماد"}
                        you={"هذا"}
                      />
                    </button>
                    <button>
                      <RequestsModelHost
                        onclick={() => {
                          err();
                          idImgRejected(e._id);
                        }}
                        isLoading={isLoading}
                        logo={<AiFillDislike size={20} />}
                        color={"text-redz"}
                        bgcolor={"bg-redz"}
                        user={"المستخدم؟ "}
                        pragraph={" .لا يمكن الرجوع في هذا الإجراء"}
                        labol={"    رفض"}
                        you={"هذا"}
                      />
                    </button>
                    <p className="bg-headtable/10 w-32 py-3 text-headtable md:hidden block font-bold ">
                      الحالة
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className=" border-0  text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {e.pendingProperties}
                    <p className="bg-headtable/10 w-32 py-3 text-headtable md:hidden block font-bold ">
                      الوحدات المعلقة
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className=" border-0  text-center ">
                  <div className="ml-6 flex justify-between md:block  ">
                    {new Date(e.createdAt).toLocaleDateString("en-GB")}
                    <p className="bg-headtable/10 w-32 py-3 text-headtable md:hidden block font-bold ">
                      تاريخ الطلب
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="rounded-r-xl border-0 text-center ">
                  <Link
                    className="ml-8 flex justify-between "
                    to={routes.requestsuserdetails(e._id)}
                  >
                    <Link
                      className="md:ml-10 "
                      to={routes.requestsuserdetails(e._id)}
                    >
                      {e.name}
                    </Link>
                    <div className=" md:mr-11 w-10 h-10 object-fill  rounded-full ml-2">
                      {e.avatar ? (
                        <img
                          className="w-10 h-10 object-fill rounded-full"
                          src={e.avatar}
                          alt=""
                        />
                      ) : (
                        <Avatar name={e.name} />
                      )}
                    </div>
                    <p className="bg-headtable/10 w-32 py-3 px-2 text-headtable md:hidden block font-bold ">
                      الاسم
                    </p>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
            {data?.length === 0 && !searchedName && (
              <Table.Row textAlign="center">
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center">
                  <div className=" flex justify-between md:block  ">
                    <p className="  text-maincolor text-5xl  block font-bold py-4 ">
                      لا يوجد مضيفون بعد
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
              </Table.Row>
            )}
            {data?.length === 0 && searchedName && (
              <Table.Row textAlign="center">
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center">
                  <div className=" flex justify-between md:block  ">
                    <p className="  text-maincolor text-5xl  block font-bold py-4 ">
                      لا يوجد مضيفون بهذا الإسم
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
                <Table.Cell className="border-0 text-center"></Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell
                className="border-0 rounded-lg bg-backgroundGray"
                colSpan="5"
              >
                {data?.length === 0 ? (
                  <div className="hidden ">
                    <RangePagination totalPages={totalPages} />
                  </div>
                ) : (
                  <div className="row ">
                    <RangePagination totalPages={totalPages} />
                  </div>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Tab.Pane>
    </div>
  );
};

export default TableHostRequests;
