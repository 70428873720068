import React from "react";
import DetailsListingNestTab from "./details-listing-nest-tabs";

const UserDetailsListings = () => {
  return (
    <div>
      <DetailsListingNestTab />
    </div>
  );
};

export default UserDetailsListings;
