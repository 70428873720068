import React, { useEffect } from "react";
import { Tab } from "semantic-ui-react";
import "../../../src/Assats/style/management-finance-tab-style.css";
import ManagementFinanceCard from "./Management-finance-card";
import { ImArrowUp, ImArrowDown, ImMinus } from "react-icons/im";
import { FaDollarSign } from "react-icons/fa";
import ManagementFinanceFees from "./Management-finance-fees";
import MangementFinaceTabOne from "./Mangement-finace-tab-one";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import MangementFinaceTabTwo from "./Mangement-finace-tab-two";
import MangementFinaceTabThree from "./Mangement-finace-tab-three";
const panes = ({ data, isLoading, setForceReload }) => [
  {
    menuItem: "الكل",
    render: () => (
      <Tab.Pane loading={isLoading}>
        <div className=" bg-backgroundGray  ">
          <MangementFinaceTabOne alltime={data?.allTime} />
        </div>
        <ManagementFinanceFees />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "اليوم",
    render: () => (
      <Tab.Pane loading={isLoading}>
        <div className=" bg-backgroundGray  ">
          <MangementFinaceTabTwo today={data?.today} />
        </div>
        <ManagementFinanceFees />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "  الأسبوعين السابقين ",
    render: () => (
      <Tab.Pane loading={isLoading}>
        <div className=" bg-backgroundGray  ">
          <MangementFinaceTabThree lasttwoweek={data?.lastTwoWeeks} />
        </div>
        <ManagementFinanceFees />
      </Tab.Pane>
    ),
  },
];

const ManagementFinanceTab = () => {
  const [forceReload, setForceReload] = React.useState(false);

  const { run, isLoading, isError, error, data } = useAxios({ data: [] });
  useEffect(() => {
    run(authAxios.get(api.finances.default));
  }, [forceReload, run]);
  return (
    <div className="bg-backgroundGray  ">
      <div className="md:hidden   ">
        <Tab
          menu={{ fluid: true, vertical: true }}
          panes={panes({ data, isLoading, setForceReload })}
        />
      </div>
      <div className="hidden md:contents round  ">
        <Tab
          menu={{ borderless: true, attached: false, tabular: false }}
          panes={panes({ data, isLoading, setForceReload })}
        />
      </div>
    </div>
  );
};

export default ManagementFinanceTab;
