import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import { constructAPIQuery } from "../../utils/pagination-utils";
import RangePagination from "../common/range-pagination";
import PropetesDetailsReviews from "./propetes-details-reviews";

const Reviews = ({ id }) => {
  const { propertyId } = useParams();

  const { search } = useLocation();
  const apiQuery = constructAPIQuery(search);

  const { run, isLoading, data, isError, error, totalPages } = useAxios({
    withPagination: true,
  });

  useEffect(() => {
    run(
      authAxios.get(
        `${
          api.admin.properties.reviews + propertyId
        }/all?range[0]=0&range[1]=999`
      )
    );
  }, [propertyId, run, apiQuery]);

  return (
    <div className="h-auto w-full rounded-xl shadow my-8 bg-white mx-auto   ">
      <div className="flex flex-row-reverse justify-between px-8 pt-7 ">
        <h1 className="text-2xl text-maincolor ">المراجعات</h1>
      </div>

      <div className="flex flex-row-reverse gap-2  overflow-x-scroll overflow-hidden  ">
        {data?.map((c) => (
          <div key={c?.user?._id}>
            {console.log(c.user.name)}
            <PropetesDetailsReviews
              use={c?.user?.name}
              description={c?.description}
              rate={c?.rate}
              avatar={c?.user?.avatar}
            />
          </div>
        ))}
        <div className="flex flex-row-reverse gap-2  ">
          {data?.length === 0 && (
            <PropetesDetailsReviews
              use={"لا توجد مراجعات بعد"}
              description={""}
              rate={0}
              avatar={""}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
