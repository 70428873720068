import React from "react";
import { Message } from "semantic-ui-react";

const ResponseError = ({ error }) => {
  return (
    <Message className="my-4" header="Error" error list={error?.message} />
  );
};

export default ResponseError;
