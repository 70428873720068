import React from "react";
import mask from "../../../../src/pages/landing-page/assets/Maskkk.svg";

const OurServicescard = ({ logo, text }) => {
  return (
    <div className="mx-auto ">
      <div className="w-60 h-52 shadow-lg rounded-lg relative my-4 z-[2]">
        <img className="absolute right-0 z-[-1]" src={mask} alt="" />
        <div>
          <img className="mx-auto pt-9  " src={logo} alt="" />
          <p className="text-center font-medium text-lg pt-8">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default OurServicescard;
