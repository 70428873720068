import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import api from "../../../api";
import { authAxios } from "../../../config/axios-config";
import useAxios from "../../../lib/use-axios";
import { GiIdCard } from "react-icons/gi";

const BasicInfo = () => {
  const { userId } = useParams();
  const { run, isLoading, isError, error, data } = useAxios({ data: {} });
  console.log(data);

  useEffect(() => {
    run(authAxios.get(api.user.userlostinfo + userId));
  }, [run, userId]);

  return (
    <Tab.Pane loading={isLoading}>
      <div className="w-full mx-8 md:mx-auto bg-backgroundGray ">
        <div className="sm:grid flex flex-wrap md:grid-cols-2 lg:grid-cols-3 grid-rows-4 gap-10 my-16 w-full  mx-16 sm:mx-auto">
          <div className="w-80 h-14 bg-formcolor/10 border-b-2 text-right px-4  border-maincolor mx-auto ">
            <h6 className="pb-1  text-maincolor">تاريخ الميلاد</h6>
            <p className="text-lg pb-0.5">
              {new Date(data?.birthdate).toLocaleDateString() === "Invalid Date"
                ? "لا يوجد "
                : new Date(data?.birthdate).toLocaleDateString()}
            </p>
          </div>
          <div className="w-80 h-14 bg-formcolor/10 border-b-2 text-right px-4 border-maincolor mx-auto">
            <h6 className="pb-1  text-maincolor">رقم الجوال</h6>
            <p className="text-lg pb-0.5">{data?.phone}</p>
          </div>
          <div className="w-80 h-14 bg-formcolor/10 border-b-2 text-right px-4 border-maincolor mx-auto">
            <h6 className="pb-1  text-maincolor">الاسم</h6>
            <p className="text-lg pb-0.5">{data?.name}</p>
          </div>
          <div className="w-80 h-14 bg-formcolor/10 border-b-2 text-right px-4 border-maincolor mx-auto">
            <h6 className="pb-1  text-maincolor">البريد الإلكتروني</h6>

            <p className="text-lg pb-0.5">
              {data?.email ? data?.email : "لا يوجد"}
            </p>
          </div>
          <div className="col-span-2 row-span-2 sm:w-[49rem] break-all  bg-formcolor/10 border-b-2 text-right px-4 border-maincolor mx-auto  w-80 ">
            <h6 className="  text-maincolor pb-2">حول</h6>
            <p className="text-lg pb-0.5">
              {data?.description ? data?.description : "لا يوجد"}
            </p>
          </div>
          <div className="w-80 h-14 bg-formcolor/10 border-b-2 text-right px-4 border-maincolor mx-auto">
            <h6 className="pb-1  text-maincolor">الجنس</h6>
            <p className="text-lg pb-0.5">
              {data?.gender === "male"
                ? "ذكر"
                : data?.gender === "female"
                ? "انثى"
                : "لا يوجد"}
            </p>
          </div>
          <div className=" w-80 h-14 mx-auto ">
            <h1 className="text-maincolor  pb-2 text-right ">وثائق الهوية</h1>
            <div className="flex justify-between w-80 h-14">
              <p className="w-36 h-20 border-2 border-collapse object-cover border-maincolor rounded-xl ">
                {data?.idImages?.length === 0 ? (
                  <div className=" mx-auto">
                    <GiIdCard
                      className="mx-auto pb-4 text-maincolor"
                      size={80}
                    />
                  </div>
                ) : (
                  <img
                    className="w-full h-full rounded-xl"
                    src={data?.idImages && data?.idImages[0]?.url}
                    alt="id"
                  />
                )}
                {/* <img
                  className=" w-full h-full rounded-xl"
                  src={data?.idImages && data?.idImages[0]?.url}
                  alt="id"
                /> */}
              </p>
              <p className="w-36 h-20 border-2 object-fill border-maincolor rounded-xl">
                {data?.idImages?.length === 0 ? (
                  <div className=" mx-auto">
                    <GiIdCard
                      className="mx-auto pb-4 text-maincolor"
                      size={80}
                    />
                  </div>
                ) : (
                  <img
                    className="w-full h-full rounded-xl"
                    src={data?.idImages && data?.idImages[1]?.url}
                    alt="id"
                  />
                )}
                {/* <img
                  className="w-full h-full rounded-xl"
                  src={data?.idImages && data?.idImages[1]?.url}
                  alt="id"
                /> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Tab.Pane>
  );
};

export default BasicInfo;
