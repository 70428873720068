import React from "react";
import applelogo from "../../../../src/pages/landing-page/assets/footor-logo.svg";
import { IoIosMail } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";
import { useLanguage } from "../context/Language-Context";
import { content } from "../localization/content";
// import {
//   FaTiktok,
//   FaInstagram,
//   FaSnapchatGhost,
//   FaYoutube,
//   FaFacebook,
//   FaTwitter,
// } from "react-icons/fa";

const Footor = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  return (
    <div className="bg-[#0000001A]">
      <div className="max-w-7xl mx-auto">
        <div>
          <div className="md:flex md:justify-around  grid mx-auto ">
            <div className="mx-auto">
              <img className="my-20 h-48 w-36" src={applelogo} alt="" />
            </div>
            <div className="my-20 mx-auto">
              <h1 className="text-2xl  font-bold">{langContent.Contact_us}</h1>
              <ul className="py-4">
                <li className="flex mx-2 py-0.5">
                  <BsTelephoneFill className="text-maincolor mx-2" size={20} />
                  <p dir="ltr">+966591588855</p>
                </li>
                {/* <li className="flex mx-2 py-0.5">
                  <IoIosMail className="text-maincolor mx-2" size={20} />
                  <p>zabnh@example.co</p>
                </li> */}
                {/* <li className="flex mx-2 py-0.5">
                  <MdLocationOn className="text-maincolor mr-2" size={20} />
                  <p>
                    Lorem Ipsum is simply <br></br>dummy text of the printing.
                  </p> */}
                {/* </li> */}
              </ul>
            </div>
            <div className="my-20 mx-auto">
              <h1 className="text-2xl font-bold"> {langContent.language}</h1>
              <ul className="py-4 ">
                <li
                  className="mx-2 py-0.5 cursor-pointer hover:text-maincolor "
                  onClick={() => {
                    setLang("en");
                  }}
                >
                  {langContent.English}
                </li>
                <li
                  className="mx-2 py-0.5 cursor-pointer hover:text-maincolor "
                  onClick={() => {
                    setLang("ar");
                  }}
                >
                  {langContent.Arbic}
                </li>
              </ul>
              {/* <div className="px-4">
                <div className="border-b-2 border-selectedcolor/30 ">
                  <Dropdown
                    trigger={
                      <div className=" flex  px-2 text-xl  ">
                        <Icon name="world" />
                        <span className="capitalize  px-1 ">{lang}</span>
                      </div>
                    }
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item>En</Dropdown.Item>
                      <hr />
                      <Dropdown.Item
                        onClick={() => {
                          setLang("ar");
                          window.location.reload();
                        }}
                      >
                        AR
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="border-t-2  w-full ">
        <div className=" max-w-7xl mx-auto py-6 flex justify-center ">
          <FaTiktok
            className="bg-[#C5C0CF] text-maincolor mx-1.5 rounded-full w-10 h-10 p-2"
            size={30}
          />
          <FaInstagram
            className="bg-[#C5C0CF] text-maincolor mx-1.5 rounded-full w-10 h-10 p-2"
            size={30}
          />
          <FaSnapchatGhost
            className="bg-[#C5C0CF] text-maincolor mx-1.5 rounded-full w-10 h-10 p-2"
            size={30}
          />
          <FaYoutube
            className="bg-[#C5C0CF] text-maincolor mx-1.5 rounded-full w-10 h-10 p-2"
            size={30}
          />
          <FaFacebook
            className="bg-[#C5C0CF] text-maincolor mx-1.5 rounded-full w-10 h-10 p-2"
            size={30}
          />
          <FaTwitter
            className="bg-[#C5C0CF] text-maincolor mx-1.5 rounded-full w-10 h-10 p-2"
            size={30}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Footor;
