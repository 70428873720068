import React from "react";
import applelogo from "../../../../src/pages/landing-page/assets/apple-logo.png";
import applelogoar from "../../../../src/pages/landing-page/assets/apple-store-ar.svg";
import playlogo from "../../../../src/pages/landing-page/assets/google-play.png";
import playlogoar from "../../../../src/pages/landing-page/assets/google-play-ar.png";
import phonePic from "../../../../src/pages/landing-page/assets/Group 2653.svg";
import { useLanguage } from "../context/Language-Context";
import { content } from "../localization/content";
import { Link } from "react-router-dom";

const HeroSection = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  return (
    <div className=" max-w-7xl mx-auto h-screen0 grid md:grid-cols-2 grid-cols-1 gap-11">
      <div className=" mt-32 mx-4">
        <h1 className="py-16 font-bold text-3xl text-maincolor">
          {langContent.title}
        </h1>
        <p className="text-xl mb-20">{langContent.paragraph}</p>
        <div className="flex flex-wrap max-w-7xl  mx-auto gap-6 text-white">
          {lang === "en" ? (
            <a href="https://apps.apple.com/eg/app/zabnh/id1638165405?platform=iphone">
              <img className="w-64 mx-auto" src={applelogo} alt="" />
            </a>
          ) : (
            <a href="https://apps.apple.com/eg/app/zabnh/id1638165405?platform=iphone">
              <img className="w-[15rem] mx-auto" src={applelogoar} alt="" />
            </a>
          )}
          {lang === "en" ? (
            <a href="https://play.google.com/store/apps/details?id=com.spiritude.zabnh">
              <img className="w-64 mx-auto" src={playlogo} alt="" />
            </a>
          ) : (
            <a href="https://play.google.com/store/apps/details?id=com.spiritude.zabnh">
              <img className="w-[18rem] mx-auto" src={playlogoar} alt="" />
            </a>
          )}
        </div>
      </div>
      <div className="">
        <img
          className="w-full h-full object-cover "
          src={phonePic}
          alt="phone pic"
        />
      </div>
    </div>
  );
};

export default HeroSection;
