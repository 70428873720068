import React from "react";
import ReaquestTabs from "../Component/requests/requests-tabs.js";

const Requests = () => {
  return (
    <div className="md:max-w-7xl mx-auto max-w-sm">
      <div className=" bg-backgroundGray ">
        <ReaquestTabs />
      </div>
    </div>
  );
};

export default Requests;
