const routes = {
  landingpag: "/",

  login: "/admin/auth/login",
  requests: "/admin/requests",
  requestsuserdetails: (userId = ":userId") =>
    `/admin/requests/userdetails/${userId}`,

  userpropertiesdetails: (
    propertyId = ":propertyId",
    verification = ":verification"
  ) => `/admin/requests/properties/${propertyId}/${verification}`,

  management: "/admin/management",

  userlist: "/admin/userlist",

  userdetails: (userId = ":userId") => `/admin/userlist/userdetails/${userId}`,

  userdetailsproperties: (propertyId = ":propertyId") =>
    `/admin/userlist/userdetails/properties/${propertyId}`,

  properties: "/admin/properties",

  propertiesdetail: (propertyId = ":propertyId") =>
    `/admin/properties/${propertyId}/detail`,

  massages: "/admin/massages",

  massageroom: (userId = ":userId") => `/admin/massages/massageroom/${userId}`,

  wallet: "/admin/wallet",

  walletuserdetails: (userId = ":userId") =>
    `/admin/wallet/userdetails/${userId}`,

  walletuserdetailsProperties: (propertyId = ":propertyId") =>
    `/admin/wallet/userdetails/properties/${propertyId}`,

  feedback: "/admin/Feedback",

  settings: "/admin/settings",
};

export default routes;
