import React, { useEffect } from "react";
import { Tab } from "semantic-ui-react";
import icon from "../../../src/Assats/icon/claudy.svg";

import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import ResponseError from "../common/response-error";
import ManagementCard from "./Management-card";
import ManagementModal from "./Management-model";

const MangementCity = () => {
  const [forceReload, setForceReload] = React.useState(false);
  const { run, isLoading, isError, error, data } = useAxios({ data: [] });

  useEffect(() => {
    run(authAxios.get(api.cities.default + "?range[0]=0&range[1]=5"));
  }, [forceReload, run]);
  return (
    <Tab.Pane loading={isLoading}>
      {isError && <ResponseError error={error} />}
      <div className="   grid    md:mx-4 lg:grid-cols-3  md:grid-cols-2 grid-cols-1  md:gap-10  grid-rows-3 pt-4 bg-backgroundGray">
        {data.map((c) => (
          <div key={c._id} className="md:my-0 mx-8 md:mx-0 my-4">
            <ManagementCard
              nam={c.name.ar}
              pic={c.image.url}
              id={c._id}
              title={"تعديل المدينة "}
              placeholderar={"تعديل المدينة "}
              placeholderen={"edit city"}
              namevalar={c.name.ar}
              namevalen={c.name.en}
              content={" تعديل  المدينة "}
              endpont={api.cities.default + c._id}
              endpointimg={api.cities.citiesimg + c._id}
              reload={() => setForceReload((p) => !p)}
            />
          </div>
        ))}
        <div className="md:my-0 my-4 md:mx-auto mx-8">
          <ManagementModal
            text={"  إضافة   المدن +"}
            title={"إضافة المدن"}
            placeholderar={"اسم المدينة"}
            placeholderen={"add new city"}
            content={"إضافة مدينة جديدة"}
            upload={"أدرج صورة"}
            icon={icon}
            endpont={api.cities.default}
            reload={() => setForceReload((p) => !p)}
          />
        </div>
      </div>
    </Tab.Pane>
  );
};

export default MangementCity;
