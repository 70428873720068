import React from "react";
import mainLogo from "../../../../src/pages/landing-page/assets/main-logo-navbare.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { useLanguage } from "../context/Language-Context";
import { content } from "../localization/content";

const Navbar = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [nav, setNave] = React.useState(false);
  const handelNav = () => {
    setNave(!nav);
  };
  const onRouteClick = (e, { name, route }) => {
    const anchor = document.querySelector(route);
    anchor.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  return (
    <div className="w-screen h-[80px] bg-white fixed drop-shadow-lg z-30">
      <div className="px-4 flex justify-between items-center max-w-7xl mx-auto h-full">
        <div className="flex ">
          <h1 className="font-bold text-3xl mr-4 sm:text-4xl cursor-default">
            <img src={mainLogo} alt="" />
          </h1>
        </div>
        <div className="flex ">
          <ul className=" hidden sm:flex sm:gap-11 ">
            <a
              href="#Services"
              onClick={onRouteClick}
              className="pt-2 cursor-pointer hover:text-maincolor"
            >
              {langContent.Services}
            </a>
            <a
              href="#About_us"
              onClick={onRouteClick}
              className="pt-2 cursor-pointer hover:text-maincolor"
            >
              {langContent.About_us}
            </a>
            <a
              href="#Contact_us"
              onClick={onRouteClick}
              className="pt-2 cursor-pointer hover:text-maincolor"
            >
              {langContent.Contact_us}
            </a>
            {/* <button className=" hover:bg-maincolor/90 bg-maincolor py-1.5 px-8 rounded-lg text-white">
              Login
            </button> */}
          </ul>
        </div>
        <div className="md:hidden cursor-pointer" onClick={handelNav}>
          {nav ? (
            <MdClose size={25} className=" hover:text-maincolor " />
          ) : (
            <GiHamburgerMenu size={25} className=" hover:text-maincolor " />
          )}
        </div>
      </div>
      <ul
        className={
          nav
            ? "flex flex-col  py-2 bg-zinc-200  w-full px-8 sm:hidden absolute "
            : "hidden"
        }
      >
        <a
          href="#Services"
          onClick={onRouteClick}
          className="border-b-4  cursor-pointer hover:text-maincolor py-6 border-zinc-300"
        >
          {langContent.Services}
        </a>
        <a
          href="#About_us"
          onClick={onRouteClick}
          className="border-b-4  cursor-pointer hover:text-maincolor py-6 border-zinc-300"
        >
          {langContent.About_us}
        </a>
        <a
          href="#Contact_us"
          onClick={onRouteClick}
          className="border-b-4  cursor-pointer hover:text-maincolor py-6 "
        >
          {langContent.Contact_us}
        </a>
        {/* <button className=" hover:bg-maincolor/90 bg-maincolor py-2 px-8 rounded-lg text-white">
          Login
        </button> */}
      </ul>
    </div>
  );
};

export default Navbar;
