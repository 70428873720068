export const content = {
  en: {
    // nav
    Services: "Services",
    About_us: "About us",
    Contact_us: " Contact us",
    // heroo
    title: "Book and spend a perfect vacation with Zabnh!",
    paragraph:
      "Explore a lot of available units for rent for tourism or residence purposes, and enjoy the best  experience with Zabnh. Download Zabnh app  now from here!",
    // Our Services
    OurServices: "Our Services",
    Findproperty: "Find a property",
    Payonline: "Pay online",
    chatwithus: "Chat with us",
    search: "Search by location",
    // About Us
    AboutUs: "About Us",
    aboutone:
      "Zabnh application serves as an intermediary between people who wish to show their units",
    abouttow:
      "such as (chalets, resorts, houses, apartments, cottages, or even a room) and those who want to reserve them.",
    abutthree:
      "Through the application, you can compare and choose the ideal unit for you.",
    abutfore:
      "Zabnh application is the preferred choice for booking and spending the perfect vacation in the KSA.",

    // Contact Us
    ContactUs: "Contact Us",
    FullName: "Full Name",
    Email: "Email",
    write: "Write anything you want ..",
    submit: "submit",
    // footor
    language: "language",
    English: "English",
    Arbic: "Arabic",
  },

  // ----------------      ------------------// ////////////////////////////               ------------------------------------------------------------- /////
  ar: {
    // nav
    Services: "معلومات عنا",
    About_us: "نبذة عنا",
    Contact_us: " اتصل بنا",
    // heroo
    title: "احجز وأقضي إجازة رائعة مع زبنة!",
    paragraph:
      "اكتشف الكثير من الوحدات المتاحة للإيجار لأغراض السياحة أو الإقامة ، واستمتع بأفضل تجربة مع زبنة. حمل تطبيق زبنة الآن من هنا!",
    // Our Services
    OurServices: " خدماتنا",
    Findproperty: "ابحث عن الوحدات",
    Payonline: "الدفع عبر الإنترنت",
    chatwithus: "دردش معنا",
    search: "البحث بالموقع",
    // About Us
    AboutUs: "معلومات عنا",
    aboutone: "تطبيق زبنة يعمل كوسيط بين الأشخاص الراغبين في إظهار وحداتهم",
    abouttow:
      "مثل (شاليهات ، منتجعات ، منازل ، شقق ، أكواخ ، أو حتى غرفة) ومن يريد حجزها.",
    abutthree: "من خلال التطبيق يمكنك المقارنة واختيار الوحدة المثالية لك.",
    abutfore:
      "تطبيق زبنه هو الخيار المفضل للحجز وقضاء الإجازة المثالية في المملكة العربية السعودية.",

    // Contact Us
    ContactUs: "اتصل بنا",
    FullName: " الاسم الكامل",
    Email: " البريد الإلكتروني",
    write: "اكتب أي شيء تريده ..",
    submit: "  إرسال",
    // footor
    language: "اللغة",
    English: "اللغة الإنجليزية",
    Arbic: "اللغة العربية",
  },
};
