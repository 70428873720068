import React from "react";
import useQuery from "./use-query";

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false);
  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);
  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch]
  );
}

const defaultInitialState = {
  status: "idle",
  data: null,
  error: null,
  withPagination: false,
  totalPages: null,
  page: null,
  limit: 25,
};

function useAxios(initialState) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });
  const [
    { status, data, error, limit, withPagination, totalPages, page },
    setState,
  ] = React.useReducer((s, a) => ({ ...s, ...a }), initialStateRef.current);

  const safeSetState = useSafeDispatch(setState);

  const query = useQuery();

  const setData = React.useCallback(
    (data) => safeSetState({ data, status: "resolved" }),
    [safeSetState]
  );

  const setTotalPages = React.useCallback(
    (totalPages) => safeSetState({ totalPages, status: "resolved" }),
    [safeSetState]
  );

  const setPage = React.useCallback(
    (page) => safeSetState({ page, status: "resolved" }),
    [safeSetState]
  );

  const setError = React.useCallback(
    (error) => safeSetState({ error, status: "rejected" }),
    [safeSetState]
  );
  const reset = React.useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState]
  );

  const run = React.useCallback(
    (promise, config = {}) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAxios().run must be a promise. Maybe a function that's passed isn't returning anything?`
        );
      }
      safeSetState({ status: "pending" });
      return promise.then(
        (response) => {
          if (withPagination) {
            const allData = response.headers["content-range"].split("/")[1];
            setTotalPages(Math.ceil(allData / limit));
          }

          setData(response?.data);
          return response;
        },
        (error) => {
          const errorMessage = error?.response?.data;
          if (config.throwOnError) throw new Error(errorMessage);
          setError(errorMessage);
          return Promise.reject(errorMessage);
        }
      );
    },
    [safeSetState, setData, setError]
  );

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === "idle",
    isLoading: status === "pending",
    isError: status === "rejected",
    isSuccess: status === "resolved",

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
    totalPages,
    limit,
    page,
  };
}

export default useAxios;
