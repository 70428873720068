import React from "react";
import house from "../../../../src/pages/landing-page/assets/house.svg";
import pay from "../../../../src/pages/landing-page/assets/pay-logo.svg";
import chat from "../../../../src/pages/landing-page/assets/chat -logo.svg";
import distant from "../../../../src/pages/landing-page/assets/distance-logo.svg";
import OurServicescard from "./Our-Services-card";
import { useLanguage } from "../context/Language-Context";
import { content } from "../localization/content";

const OurServices = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  return (
    <div id="Services" className="w-full pt-20 ">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-center font-bold text-3xl py-4">
          {langContent.OurServices}
        </h1>
        <div className="grid md:grid-cols-4 py-4 sm:grid-cols-2 grid-cols-1   ">
          <OurServicescard logo={house} text={langContent.Findproperty} />
          <OurServicescard logo={chat} text={langContent.Payonline} />
          <OurServicescard logo={pay} text={langContent.chatwithus} />
          <OurServicescard logo={distant} text={langContent.search} />
        </div>
      </div>
    </div>
  );
};

export default OurServices;
