import React from "react";
import { useHistory } from "react-router-dom";
import useQuery from "./use-query";

const useFilter = (name, value = "") => {
  const [filter, setFilter] = React.useState(value);

  const history = useHistory();
  const query = useQuery();

  const onFilterChange = (newValue) => {
    query.set(name, newValue);
    history.replace(`?${query.toString()}`);
  };

  React.useEffect(() => {
    setFilter(query.get(name) || value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  return [filter, onFilterChange];
};

export default useFilter;
