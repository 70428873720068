import React from "react";
import { Button, Modal } from "semantic-ui-react";
import warning from "../../../src/Assats/icon/warning.svg";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

function PendingApprovalModal({ logo, color, labol }) {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;

  return (
    <div className="mx-auto rounded-2xl ">
      <Modal
        dimmer={dimmer}
        closeIcon
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        className="w-[40rem] h-[30rem] rounded-2xl  "
        trigger={
          <div>
            <button
              className={` " flex px-2 ${color} " `}
              onClick={() =>
                dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })
              }
            >
              {logo}
              <span className="text-black text-lg">{labol}</span>
            </button>
          </div>
        }
      >
        <div className="rounded-2xl flex justify-center mt-16 text-2xl">
          <Modal.Content>
            <img className="mx-auto pb-5" src={warning} alt="warning" />
            <p>
              لم يتم التحقق من معرف هذا المضيف حتى الآن
              <br></br>
              <span className="flex justify-center">
                وهو في انتظار الموافقة.
              </span>
            </p>
          </Modal.Content>
        </div>
        <Modal.Actions className="border-0 p-0 m-0 flex bg-white flex-col  mt-12  items-center">
          <button
            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
            className=" w-80 h-12  text-maincolor border-2 mt-5 border-maincolor rounded-lg "
          >
            إلغاء
          </button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default PendingApprovalModal;
