import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import routes from "../../routes";
import Avatar from "../common/Avatar";

const UserMessage = ({ data, resetMessages, reloadUsers }) => {
  const history = useHistory();

  const truncateString = (str, num) => {
    if (str?.length > num) {
      return "..." + str.slice(0, num);
    } else {
      return str;
    }
  };
  const { pathname } = useLocation();

  return (
    <div className="w-72 bg-white z-10  cursor-pointer ">
      <div
        onClick={() => {
          resetMessages();
          history.push(routes.massageroom(data?.user?._id));
        }}
      >
        <div
          className={` ${
            pathname.endsWith(data?.user?._id)
              ? "bg-[#4B38691C]"
              : "bg-transparent"
          }`}
        >
          <div className="flex justify-end p-5    ">
            <div className="flex flex-col justify-items-end px-2 ">
              <h1 className="flex justify-end  ">{data?.user?.name} </h1>
              <p className="text-sm break-all overflow-hidden w-44  text-gray-500">
                {truncateString(data?.lastMessage?.message, 25)}
              </p>
            </div>
            {data?.user?.avatar ? (
              <img
                className="w-10 h-10  object-fill rounded-full"
                src={data?.user?.avatar}
                alt=""
              />
            ) : (
              <Avatar name={data?.user?.name} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMessage;
