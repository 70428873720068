import { BrowserRouter, Route, withRouter, Switch } from "react-router-dom";
import authlayout from "./layouts/auth-layout";
import sidebarlayout from "./layouts/app-layout";
import { Redirect } from "react-router-dom";
import landingpage from "./pages/landing-page/landingpage";
import React from "react";

function App() {
  React.useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.startsWith("/admin")) document.title = "Zabnh | Dashboard";
    return () => (document.title = "Zabnh");
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={withRouter(landingpage)} />
          <Route path="/admin/auth" component={withRouter(authlayout)} />
          <Route path="/admin" component={withRouter(sidebarlayout)} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
