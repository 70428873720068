export function constructAPIQuery(search, from = "range[0]", to = "range[1]") {
  const query = new URLSearchParams(search);

  const limit = 25; // static for now
  const page = query.get("page") || 1;

  const firstRange = (page - 1) * limit;
  const secondRange = page * limit - 1;

  const range = `${from}=${firstRange}&${to}=${secondRange}`;

  query.delete("page");
  query.delete("limit");

  const queryString = query.toString();

  return `?${queryString === "" ? range : `${queryString}&${range}`}`;
}
