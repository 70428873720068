import React from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";

function ImgBiggerModel({ pic }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        pic ? (
          <img
            className="w-full h-full  object-cover border-maincolor rounded-xl"
            src={pic}
            alt=" "
          />
        ) : (
          <p className="text-maincolor font-bold text-xl">لا يوجد مستند</p>
        )
      }
    >
      {pic ? (
        <img
          className="w-full h-full   border-maincolor rounded-5xl"
          src={pic}
          alt=" "
        />
      ) : (
        <p className="hidden"></p>
      )}
    </Modal>
  );
}

export default ImgBiggerModel;
