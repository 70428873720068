import React from "react";
import { FaDollarSign } from "react-icons/fa";
import { ImArrowDown, ImArrowUp, ImMinus } from "react-icons/im";
import { Tab } from "semantic-ui-react";
import ManagementFinanceCard from "./Management-finance-card";
import ManagementFinanceFees from "./Management-finance-fees";

const MangementFinaceTabThree = ({ lasttwoweek }) => {
  const fixed = (e) => e?.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

  return (
    <div className="flex flex-col  bg-backgroundGray   md:rounded-2xl">
      <div className="grid mx-20  lg:mx-24  gap-4 sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-3">
        <ManagementFinanceCard
          num={fixed(lasttwoweek?.netProfit)}
          title={"صافي الربح"}
          logo={<FaDollarSign size={60} />}
          bg={"bg-[#00B518]"}
          shadowbg={"shadow-[#00B518]"}
        />
        <ManagementFinanceCard
          num={fixed(lasttwoweek?.totalExpenses)}
          title={"المصروفات الكلية"}
          logo={<ImArrowUp size={60} />}
          bg={"bg-[#F65454]"}
          shadowbg={"shadow-[#F65454]"}
        />
        <ManagementFinanceCard
          num={fixed(lasttwoweek?.totalIncome)}
          title={"إجمالي الدخل"}
          logo={<ImArrowDown size={60} />}
          bg={"bg-[#5B94E8]"}
          shadowbg={"shadow-[#5B94E8]"}
        />
      </div>
      {/* <div className="flex ml-2 pr-4 ">
          <ManagementFinanceFees />
        </div> */}
    </div>
  );
};

export default MangementFinaceTabThree;
