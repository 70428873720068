import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import api from "../../api";
import login_pic from "../../Assats/icon/logingimg.png";
import logo from "../../Assats/icon/logo.jpg";
import { axios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import useLocalstorage from "../../lib/use-localstorage";
import routes from "../../routes";

const ENTER_KEY = 13;

const Login = () => {
  const [_, setToken] = useLocalstorage("token");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eror, setEror] = useState(true);

  const [passwordType, setPasswordType] = useState("password");

  console.log(password);

  const handlePasswordChange = (evnt) => {
    setPassword(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const history = useHistory();
  const { run, isLoading, isError, error } = useAxios();

  const handelKeyDowen = (e) => {
    if (e.which === ENTER_KEY) login();
  };

  const login = () => {
    const body = {
      email,
      password,
    };
    run(axios.post(api.auth.login, body))
      .then((res) => {
        setToken(res.data.token);
        history.push(routes.requests);
      })
      .catch((err) => {
        console.log(err.response);
        setEror(false);
      });
  };

  return (
    <div>
      <img className="w-full h-screen relative " src={login_pic} alt="bg" />
      <div className="bg-white w-[384px] h-[380px]  z-30 absolute my-auto mx-auto left-0 right-0  top-[50%] bottom-[50%] text-center rounded-xl ">
        <h1>
          <img
            className="mx-auto w-[94px] h-[36] my-9 mb-5"
            src={logo}
            alt="logo"
          />
        </h1>
        <div className="flex flex-col text-black ">
          <p className="text-deltext font-bold h-10 text-xl mb-6">
            {eror ? "" : " تأكد من حسابك أو كلمة المرور"}
            <p className="mb-3 ">
              {password.length < 6
                ? "  كلمة المرور يجب أن تكون أكثر من خمسة أحرف"
                : ""}
            </p>
          </p>
          <input
            onChange={(event) => setEmail(event.target.value)}
            className="w-[320px] h-[56px] mx-auto bg-[#16498614] text-right border-b-2 border-[#878787] my-2 hover:border-[#4B3869] focus:outline-none p-2"
            type="email"
            placeholder="بريد المستخدم"
            autoComplete="email"
          />
          <div className="flex flex-row-reverse justify-center  h-14 mx-auto  my-3   text-right   border-b-2 border-[#878787]   hover:border-[#4B3869] focus:outline-none  ">
            <input
              onKeyUp={handelKeyDowen}
              onChange={(event) => {
                setPassword(event.target.value);
                handlePasswordChange();
              }}
              type={passwordType}
              value={password}
              className="w-80  h-14 mx-auto bg-[#16498614]  border-b-2 border-[#878787]   hover:border-[#4B3869] focus:outline-none pr-4 text-right "
              placeholder="كلمة السر"
            />
            <button
              className="bg-[#16498614] border-[#878787]   hover:border-[#4B3869]  pl-4 "
              onClick={togglePassword}
            >
              {passwordType === "password" ? (
                <FaEye size={30} />
              ) : (
                <FaEyeSlash size={30} />
              )}
            </button>
          </div>
          <Button
            type="submit"
            loading={isLoading}
            onClick={password.length < 6 ? "" : login}
            className={` ${
              password.length < 6
                ? " bg-maincolor/50 text-black w-[320px] h-[48px] rounded-lg mx-auto my-2"
                : " bg-[#4B3869] text-[#FCFCFC] w-[320px] h-[48px] rounded-lg mx-auto my-2"
            } `}
          >
            تسجيل الدخول
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
